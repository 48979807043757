
//checks if obj exists in array of objects
export const objectInObjectArray = (objKey: string, objValue: string, arrayOfObjects: any, caseSensitive=true) => {
    for (let i = 0; i < arrayOfObjects.length; i++) {
        if (!caseSensitive) {
            if (arrayOfObjects[i][objKey].toLowerCase() === objValue.toLowerCase()) {
                return true;
            }
        }
        if (arrayOfObjects[i][objKey] === objValue) {
            return true;
        }
    }
    return false;
}


export const sortArrayOfObjects = (arrayOfObjects: any[], sortKey: string) => {
    const sorted = [...arrayOfObjects].sort((a, b) => (Number(a[sortKey]) < Number(b[sortKey])) ? 1 : ((Number(b[sortKey]) < Number(a[sortKey])) ? -1 : 0))
    return sorted
}

export const getIndexOfObject = (arrayOfObjects: any[], objectKey: string, objValue: string) => {
    for (let i = 0; i < arrayOfObjects.length; i++) {
        if (arrayOfObjects[i][objectKey] === objValue) {
            return i
        }
    }
    return -1

}

export const getIndexesOfObjects = (arrayOfObjects: any[], objectKey: string, objValue: string) => {
    const indexes: number[] = []
    for (let i = 0; i < arrayOfObjects.length; i++) {
        if (arrayOfObjects[i][objectKey] === objValue) {
            indexes.push(i)
        }
    }
    return indexes
}

export const countValueInArrayOfObjects = (arrayOfObjects: any[], countKey: string, countValue: string) => {
    return arrayOfObjects.filter((item) => item[countKey] === countValue).length
}
/**
 * Function that takes in an array of objects and if there is a duplicate value in the findKey, it will return the index with the highest highestValueKey
 * @param arrayOfObjects 
 * @param findKey
 * @param findKeyValue 
 * @param highestValueKey 
 * @returns 
 */
export const getObjectIndexWithHighestValue = (arrayOfObjects: any[], objectKey: string, objValue: string, highestValueKey: string) => {
    const indexes = getIndexesOfObjects(arrayOfObjects, objectKey, objValue)

    if (indexes.length === 0) return -1 

    let highestValue = arrayOfObjects[indexes[0]][highestValueKey]
    let index = indexes[0]
    for (let i = 0; i < indexes.length; i++) {
        if (arrayOfObjects[indexes[i]][highestValueKey] > highestValue) {
            highestValue = arrayOfObjects[indexes[i]][highestValueKey]
            index = indexes[i]
        }
    }
    
    return index
} 


export function chunkArray(array:any, chunkSize: number) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

