import React, { useEffect, useState } from 'react';
import { APP_NAME } from '../../constants/global';
import { useAuth } from '../../services/AuthHooks';

const SignupForm: React.FC = () => {
  useEffect(() => {
    document.title = `${APP_NAME} - Sign up`;
  }, []);

  const { signUp, confirmSignUp } = useAuth();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isSignedUp, setIsSignedUp] = useState(false);

  const onSubmitSignup = (e: React.SyntheticEvent) => {
    e.preventDefault();
    signUp(username, email, password).then(res =>
      res ? setIsSignedUp(true) : setIsSignedUp(false)
    );
  };

  return (
    <div>
      {isSignedUp ? (
        <VerificationForm confirmSignUp={confirmSignUp} />
      ) : (
        <form onSubmit={onSubmitSignup}>
          <h1 className="text-2xl font-bold text-center mb-8">Sign up</h1>
          <h3 className="mb-1">username</h3>
          <input
            className="w-full mb-4 rounded-full py-2 px-6 p-2 border border-gray-200"
            value={username}
            onChange={e => setUsername(e.target.value)}
            placeholder="Your Username"
            type="username"
            id="username"
            name="username"
          />
          <h3 className="mb-1">email</h3>
          <input
            className="w-full mb-4 rounded-full py-2 px-6 p-2 border border-gray-200"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Your Email"
            type="email"
            id="email"
            name="email"
          />

          <h3 className="mb-1">password</h3>
          <input
            className="w-full mb-4 rounded-full py-2 px-6 p-2 border border-gray-200"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Your Password"
            type="password"
            id="password"
            name="password"
          />

          <div className="flex justify-center text-white ">
            <button className=" py-2 w-6/12 font-bold rounded-full bg-primary hover:shadow-2xl ">
              Sign up
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
interface VerificationFormProps {
  confirmSignUp: (username: string, code: string) => Promise<void>;
}

const VerificationForm: React.FC<VerificationFormProps> = ({ confirmSignUp }) => {
  const [confirmUsername, setConfirmUsername] = useState('');
  const [verificationCode, setVerificationCode] = useState('');

  const onConfirm = (e: React.SyntheticEvent) => {
    e.preventDefault();
    confirmSignUp(confirmUsername, verificationCode);
  };
  return (
    <form onSubmit={onConfirm}>
      <h3 className="mb-1">username</h3>
      <input
        className="w-full mb-4 rounded-full py-2 px-6 p-2 border border-gray-200"
        value={confirmUsername}
        onChange={e => setConfirmUsername(e.target.value)}
        placeholder="Your Username"
        type="username"
        id="username"
        name="username"
      />
      <h3 className="mt-4 mb-1">verification code</h3>
      <input
        className="w-full mb-4 rounded-full py-2 px-6 p-2 border border-gray-200"
        value={verificationCode}
        onChange={e => setVerificationCode(e.target.value)}
        placeholder="Your Verification Code"
      />
      <div className="flex justify-center text-white ">
        <button className=" py-2 w-6/12 font-bold rounded-full bg-primary hover:shadow-2xl ">
          Confirm
        </button>
      </div>
    </form>
  );
};

export default SignupForm;
