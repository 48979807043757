import { useState } from 'react';
import searchbarIcon from '../../../assets/search-dark.png';
import DatePicker from '../../../components/DatePicker';
import InputBox from '../../../components/header/InputBox';
import ToggleOuter from '../../../components/OuterTogger';
import { useComponentVisible } from '../../../helpers/hooks/ComponentVisibleHooks';
import { ConnectionAddTargets, ConnectionPopUp } from './ConnectionAddTargets';

interface ConnectionSearchProps {
  search: any;
  setSearch: React.Dispatch<React.SetStateAction<any>>;
}

const ConnectionSearch: React.FC<ConnectionSearchProps> = ({ search, setSearch }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [artist, setArtist] = useState(null);
  const [degrees, setDegrees] = useState(1);
  const [artistSelection, setArtistSelection] = useState([]);
  const [currentVersion, setCurrentVersion] = useState(search.versionDate);
  const [toggle, setToggle] = useState(false);

  const onSubmitHandler = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    if (artist && artistSelection.length !== 0) {
      toggle
        ? setSearch({
            outer: toggle,
            degreesOfFreedom: degrees,
            end: JSON.stringify([artist['id']]),
            startName: artist['name'],
            start: JSON.stringify(artistSelection.map(artist => artist['id'])),
            versionDate: currentVersion
          })
        : setSearch({
            outer: toggle,
            degreesOfFreedom: degrees,
            start: JSON.stringify([artist['id']]),
            startName: artist['name'],
            end: JSON.stringify(artistSelection.map(artist => artist['id'])),
            versionDate: currentVersion
          });
    }
  };

  return (
    <form onSubmit={onSubmitHandler} className="flex rounded-full my-auto h-8">
      <ToggleOuter toggle={toggle} setToggle={setToggle} />
      <div className="relative">
        <InputBox
          placeholder={toggle ? 'target' : 'source'}
          artist={artist}
          setArtist={setArtist}
        />
        <div ref={ref} className="relative">
          <ConnectionPopUp
            toggle={toggle}
            degrees={degrees}
            setDegrees={setDegrees}
            artistSelection={artistSelection}
            setArtistSelection={setArtistSelection}
            isComponentVisible={isComponentVisible}
            setIsComponentVisible={setIsComponentVisible}
          />
        </div>
      </div>
      <ConnectionAddTargets
        toggle={toggle}
        setIsComponentVisible={setIsComponentVisible}
        artistSelection={artistSelection}
      />
      <DatePicker currentVersion={currentVersion} setCurrentVersion={setCurrentVersion} />

      <button
        type={'submit'}
        className=" ml-6 mx-auto px-4 p-0 rounded text-center bg-white hover:bg-gray-200 "
      >
        <img className="h-3 w-3" src={searchbarIcon} />
      </button>
    </form>
  );
};
export default ConnectionSearch;
