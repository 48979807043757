import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Analytics } from 'aws-amplify';

type ContextProps = {
  user: any;
  setUser: React.Dispatch<React.SetStateAction<undefined>>;
  authenticated: boolean;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  loadingAuthState: boolean;
};

export const AuthContext = React.createContext<Partial<ContextProps>>({});

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState(undefined);
  const [authenticated, setAuthenticated] = useState<boolean>(true);
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  useEffect(() => {
    //   const assessLoggedInState = () =>{
    Auth.currentAuthenticatedUser()
      .then(user => {
        Analytics.record({
          name: 'userAuthenticated',
          attributes: {
            userEmail: user.attributes.email
          }
        });

        setUser(user);
        setAuthenticated(true);
      })
      .catch(() => {
        setAuthenticated(false);
      });
  }, []);

  return (
    //add signout function to context
    <AuthContext.Provider
      value={{
        user,
        setUser,
        setAuthenticated,
        authenticated,
        loadingAuthState
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
