/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "4bdf8de742ae4f3189411d9a2509baae",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "4bdf8de742ae4f3189411d9a2509baae",
            "region": "us-west-2"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "mappingApi",
            "endpoint": "https://qujyencst6.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:9fd9c7e8-165f-4be9-b267-ca76ffd65c94",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_4Iv3rf3z8",
    "aws_user_pools_web_client_id": "12u15amg17uija47e1jnlq51nt",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
