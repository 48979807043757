import React, { useEffect, useState } from 'react';
import { ArtistGraphHeader } from '../header/ArtistGraphHeader';
import Loading from '../Loading';
import SettingsContainer from '../settings/SettingsContainer';
import SummaryContainer from '../summary/SummaryContainer';
import { useGraph } from './GraphHooks';
import { TopArtistsListView } from '../summary/TopArtists';
import { Graph } from './Graph';

interface ArtistGraphContainerProps {
  path: string;
  params: any;
  graphType: string;
}

export const ArtistGraphContainer: React.FC<ArtistGraphContainerProps> = ({
  path,
  params,
  graphType
}) => {
  const {
    data,
    filters,
    followersScale,
    artistDetail,
    graphSummary,
    graphDataIsLoading,
    search,
    filteredNodes,
    setArtistDetail,
    onChangeFilterFollowerScale,
    onChangeFilter,
    resetFilters,
    selectAll,
    setSearch
  } = useGraph(path, params);

  const [expandGraph, setExpandGraph] = useState(false);
  const [antvData, setAntvData] = useState(data);

  useEffect(() => {
    if (data) {
      if (filteredNodes.size === 0) {
        setAntvData(data);
      }
    }
  }, [data, filteredNodes]);

  // useEffect(()=>{
  //     const fetchData = async (): Promise<void> => {
  //         const user = await Auth.currentAuthenticatedUser()
  //         Analytics.record({
  //             name:"graphVisit",
  //             attributes:{
  //               graphType: graphType,
  //               userEmail:user.attributes.email
  //             }
  //           })
  //     }
  //     fetchData()
  //  },[])

  useEffect(() => {
    setListView(false);
  }, [graphDataIsLoading]);

  const [listView, setListView] = useState(false);

  const onClickNodeArtist = function(nodeId: string) {
    for (const item in data.nodes) {
      if (data.nodes[item]['id'] === nodeId) {
        setArtistDetail({
          name: nodeId,
          followers: data.nodes[item].followers,
          url: data.nodes[item].uri,
          img: data.nodes[item].imgUrl,
          popularity: data.nodes[item].popularity,
          genres: data.nodes[item].genres,
          label: data.nodes[item].label,
          releases: data.nodes[item].lastReleases,
          color: data.nodes[item].color,
          followerGrowth: data.nodes[item].followerGrowth,
          nonNettwerkAffinity: data.nodes[item].nonNettwerkAffinity
        });
      }
    }
  };

  return (
    <section className=" flex bg-gray-100 h-full w-11/12  ">
      <div
        className={`${
          expandGraph || graphDataIsLoading ? 'h-full w-full ' : '   h-screen w-9/12'
        } relative z-50 overflow-y-scroll`}
      >
        <div
          className={`${
            graphDataIsLoading
              ? 'hidden  '
              : ' sticky top-0 right-0 left-0 z-50  mx-2 p-3 rounded bg-gray-100  '
          }`}
        >
          <ArtistGraphHeader
            graphType={graphType}
            subject={graphType === 'Genre' ? search.genre : data ? data.nodes[0].id : ''}
            search={search}
            data={data}
            setListView={setListView}
            listView={listView}
            setSearch={setSearch}
          />
        </div>
        {graphDataIsLoading ? (
          <div className="h-full flex justify-center items-center">
            <Loading className="w-56 h-56 border-8" />
          </div>
        ) : (
          <div className="h-full flex flex-col bg-gray-100">
            <div
              className={`${expandGraph ? 'h-screen pb-20' : ' pb-16 h-3/4'} relative mb-4 mx-4 `}
            >
              <div className={`h-full w-full ${listView ? 'hidden' : 'block'}`}>
                {data ? (
                  <Graph
                    gpuEnabled={graphType === 'Nettwerk'}
                    filteredData={filteredNodes}
                    data={antvData}
                    legend={data.legend}
                    handleClick={onClickNodeArtist}
                    expandGraph={expandGraph}
                    setExpandGraph={setExpandGraph}
                  />
                ) : (
                  <></>
                )}
              </div>
              {data && listView ? (
                <div className={`overflow-scroll h-full w-full shadow rounded-lg bg-white`}>
                  <TopArtistsListView
                    filteredNodes={filteredNodes}
                    onClick={onClickNodeArtist}
                    legend={data.legend}
                    sortedNodes={data.nodes}
                  />
                </div>
              ) : (
                <></>
              )}

              {!expandGraph && data ? (
                <SummaryContainer
                  legend={data.legend}
                  nodes={data.nodes}
                  graphSummary={graphSummary}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
      {!graphDataIsLoading && !expandGraph ? (
        <SettingsContainer
          filters={filters}
          followersScale={followersScale}
          artistDetail={artistDetail}
          resetFilters={resetFilters}
          onChangeFilterFollowerScale={onChangeFilterFollowerScale}
          onChangeFilter={onChangeFilter}
          selectAll={selectAll}
        />
      ) : (
        <></>
      )}
    </section>
  );
};
