import React from 'react';
import { ISummaryCommunity } from '../graph/GraphHooks';
import SummaryPoint from './SummaryPoint';
import SummaryTitle from './SummaryTitle';

interface TopGenresProps {
  community: ISummaryCommunity[] | undefined;
}

const TopGenres: React.FC<TopGenresProps> = ({ community }) => {
  return (
    <div className="summary-card  ml-3 bg-violet-50">
      <SummaryTitle title={'Community genres'} />
      {community ? (
        [
          community.length > 0 ? (
            <SummaryPoint
              key={community[0].genre}
              amount={community[0].numberOfArtists.toString()}
              description={community[0].genre}
            />
          ) : (
            <></>
          ),
          community.length > 1 ? (
            <SummaryPoint
              key={community[1].genre}
              amount={community[1].numberOfArtists.toString()}
              description={community[1].genre}
            />
          ) : (
            <></>
          ),
          community.length > 2 ? (
            <SummaryPoint
              key={community[2].genre}
              amount={community[2].numberOfArtists.toString()}
              description={community[2].genre}
            />
          ) : (
            <></>
          )
        ]
      ) : (
        <></>
      )}
    </div>
  );
};

export default TopGenres;
