import React from 'react';
import clsx, { ClassValue } from 'clsx';
import close from '../../../assets/close.png';

interface Props {
  children?: React.ReactNode;
  className?: ClassValue;
  onClickComponentVisible: (visible: boolean) => void;
}

export function Explanation({ children, className, onClickComponentVisible }: Props) {
  return (
    <div className={clsx(className, 'fixed inset-10 bg-white p-24 rounded overflow-scroll')}>
      <button onClick={() => onClickComponentVisible(false)}>
        <img className="h-10 w-10 absolute right-10 top-10" src={close} alt="close" />
      </button>
      <div className="space-y-3">
        <div>
          <h1 className="text-2xl font-bold">Questions & Answers</h1>
          <p className="mb-8 mt-4 italic font-light">
            This schedule A tool only outputs tracks & albums that are live in the{' '}
            <span className="font-bold">US market</span>. This means that it is possible that there
            are albums that might be missed because they are not live in the US market.
          </p>
          <ol className="space-y-4">
          <li>
              <QandA
                question="What is the first checkbox ie 'Do you want to update the catalogue value tableau report...'?"
                answer="If you want the catalogue value tableau report to be updated with the new Schedule A, then check this box. If you do not want to update the catalogue value tableau report, then leave this box unchecked."
              />
            </li>
            <li>
              <QandA
                question="What is the scheduleA.docx file after I generate the Schedule A?"
                answer="This is the actual output of the Schedule A, so please verify that it is correct and make any necessary changes."
              />
            </li>
            <li>
              <QandA
                question="What is the metaData.csv file after I generate the Schedule A?"
                answer="This file contains all of the metadata for the tracks that are on the Schedule A. This includes, popularity score, streams, upc of album, isrc, etc."
              />
            </li>
            <li>
              <QandA
                question="What is the copyrightData.csv file after I generate the Schedule A?"
                answer="This file contains the entire catalogue breakdown of the artist (regardless of whether the album is on the Schedule A or not).
                Here is where you see how many labels currently own rights to the artist's catalogue, and which territories they own them in. It is 
                suggested to look at this afterwards and see which albums you may have missed."
              />
            </li>
          </ol>
        </div>
      </div>
      {children}
    </div>
  );
  // ) : null;
}

const QandA = ({
  question,
  answer,
  solution
}: {
  question: string;
  answer: string;
  solution?: string;
}) => {
  return (
    <div className="space-y-1">
      <h1 className="text-xl font-bold">{question}</h1>
      <p className="italic">
        <span className="font-bold">answer:</span> {answer}
      </p>
      {solution ? (
        <p className="italic">
          <span className="font-bold">solution:</span> {solution}
        </p>
      ) : null}
    </div>
  );
};
