import React, { useEffect } from 'react';
import { ArtistGraphContainer } from '../../../components/graph/ArtistGraphContainer';
import { APP_NAME } from '../../../constants/global';

export const NettwerkContainer: React.FC = () => {
  useEffect(() => {
    document.title = `${APP_NAME} - Nettwerk`;
  }, []);
  return (
    <ArtistGraphContainer
      path={'/nettwerk'}
      graphType={'Nettwerk'}
      params={{
        degreesOfFreedom: 2,
        versionDate: ''
      }}
    />
  );
};
