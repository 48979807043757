import { IGraphData } from "../../components/graph/GraphHooks";


const getShadowColor = (followerGrowth: number) => {
    if (followerGrowth >= 1 && followerGrowth < 5) {
        return { shadowColor: 'blue', stroke: 'blue' }
    }
    if (followerGrowth >= 5) {
        return { shadowColor: 'green', stroke: 'green', lineWidth: 3 }
    }
    return { stroke: null } as any
}

export const transformAntVData = (data: IGraphData): any => {
    var sUsrAg = navigator.userAgent;
    const nodes: any = []
    const edges: any = []
    for (let i = 0; i < data.nodes.length; i++) {
        nodes.push({
            'id': data.nodes[i].id,
            'label': data.nodes[i].id,
            'size': data.nodes[i].size > 1000 ? data.nodes[i].size / 60 : data.nodes[i].size / 20,
            'style': {
                opacity: data.nodes[i].opacity,
                fill: data.nodes[i].color,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 30,
                ...getShadowColor(data.nodes[i].followerGrowth)

            },

            labelCfg: {
                position: 'top',
                style: {
                    fill: data.nodes[i].fontColor,
                }
            }
        })
    }
    for (let i = 0; i < data.links.length; i++) {
        edges.push({
            'id': i,
            'source': data.links[i].source,
            'target': data.links[i].target
        })
    }
    return {
        edges: edges,
        nodes: nodes
    }
}