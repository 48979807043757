import React from 'react';
import { ISummaryOverview } from '../graph/GraphHooks';
import SummaryPoint from './SummaryPoint';
import SummaryTitle from './SummaryTitle';

interface OverviewProps {
  overview: ISummaryOverview | undefined;
}

const Overview: React.FC<OverviewProps> = ({ overview }) => {
  return (
    <div className="summary-card bg-sapphire ">
      <SummaryTitle title={'Overview'} />
      <SummaryPoint
        amount={overview ? overview?.artists.toLocaleString() : ''}
        description={'artists'}
      />
      <SummaryPoint
        amount={overview ? overview?.avgFollowers.toLocaleString() : ''}
        description={'average followers'}
      />
      <SummaryPoint
        amount={overview ? overview?.totalFollowers.toLocaleString() : ''}
        description={'total followers'}
      />
      <SummaryPoint
        amount={overview ? overview?.avgPopularity.toString() : ''}
        description={'average popularity'}
      />
      <SummaryPoint
        amount={overview ? overview?.avgReleases.toString() : ''}
        description={'average releases (in the last year)'}
      />
    </div>
  );
};

export default Overview;
