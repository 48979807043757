import React from 'react';
import { IArtistDetail } from '../graph/GraphHooks';
import ArtistDetail from './ArtistDetail';
import FilterContainer from './FilterContainer';
import { IFilter } from './FilterHooks';

interface SettingsContainerProps {
  filters: IFilter[] | undefined;
  followersScale: {
    min: number;
    max: number;
  };
  artistDetail: IArtistDetail | undefined;
  resetFilters: () => void;
  selectAll: (currentFilters: IFilter[], value: boolean) => void;
  onChangeFilter: (filterType: string, name: string, value: boolean) => void;
  onChangeFilterFollowerScale: (value: { min: number; max: number }) => void;
}

const SettingsContainer: React.FC<SettingsContainerProps> = ({
  filters,
  onChangeFilter,
  onChangeFilterFollowerScale,
  resetFilters,
  selectAll,
  followersScale,
  artistDetail
}) => {
  return (
    <div className=" max-h-full w-3/12 overflow-scroll z-0  bg-white">
      <ArtistDetail artistDetail={artistDetail} />
      <FilterContainer
        filters={filters}
        onChangeFilter={onChangeFilter}
        followersScale={followersScale}
        onChangeFilterFollowerScale={onChangeFilterFollowerScale}
        resetFilters={resetFilters}
        selectAll={selectAll}
      />
    </div>
  );
};
export default SettingsContainer;
