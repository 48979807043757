import React from 'react';

interface GenreItemProps {
  genre: string;
}

const GenreItem: React.FC<GenreItemProps> = ({ genre }) => {
  return (
    <div className="py-1 px-4 border m-1 border-primary rounded-full">
      <p className=" text-gray-500 text-2xs font-bold">{genre}</p>
    </div>
  );
};

export default GenreItem;
