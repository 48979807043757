import React from 'react';
import { MappingTitle } from '../../components/MappingTitle';
import logo from '../../assets/map-logo.png';
import SignupForm from './SignupForm';
import LoginForm from './LoginForm';
import HomeFooter from './HomeFooter';

interface HomeProps {
  isLogin: boolean;
}

const Home: React.FC<HomeProps> = ({ isLogin }) => {
  return (
    <div className="flex ">
      <div className="flex-1 relative">
        <MappingTitle />
        <div className="flex h-screen justify-center items-center">
          <div className="w-6/12 ">{isLogin ? <LoginForm /> : <SignupForm />}</div>
        </div>
        <HomeFooter isLogin={isLogin} />
      </div>

      <div className="flex-1 flex bg-primary">
        <div className="m-auto">
          <img src={logo} className=" w-8/12 m-auto" alt="Network Map" />
        </div>
      </div>
    </div>
  );
};

export default Home;
