import { useState } from 'react';
import searchbarIcon from '../../../assets/search-dark.png';
import DatePicker from '../../../components/DatePicker';
import InputBox from '../../../components/header/InputBox';
import ToggleOuter from '../../../components/OuterTogger';

interface CommunitySearchProps {
  search: any;
  setSearch: React.Dispatch<React.SetStateAction<any>>;
}

const CommunitySearch: React.FC<CommunitySearchProps> = ({ search, setSearch }) => {
  const [artist, setArtist] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(search.versionDate);
  const [toggle, setToggle] = useState(
    Object.prototype.hasOwnProperty.call(search, 'reversed') ? search.reversed : false
  );

  const onSubmitHandler = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    if (artist) {
      setSearch({
        degreesOfFreedom: 2,
        start: artist['external_urls']['spotify'],
        startName: artist['name'],
        versionDate: currentVersion,
        reversed: toggle
      });
    }
  };

  return (
    <form onSubmit={onSubmitHandler} className="flex rounded-full mt-auto h-8">
      <ToggleOuter toggle={toggle} setToggle={setToggle} />
      <InputBox placeholder="source" artist={artist} setArtist={setArtist} />
      <DatePicker currentVersion={currentVersion} setCurrentVersion={setCurrentVersion} />

      <button
        type={'submit'}
        className=" ml-6 mx-auto px-4 p-0 rounded text-center bg-white hover:bg-gray-200 "
      >
        <img className="h-3 w-3" src={searchbarIcon} />
      </button>
    </form>
  );
};
export default CommunitySearch;
