import React, { useEffect } from 'react';
import { ArtistGraphContainer } from '../../../components/graph/ArtistGraphContainer';
import { APP_NAME } from '../../../constants/global';

export const GenreContainer: React.FC = () => {
  useEffect(() => {
    document.title = `${APP_NAME} - Genre`;
  }, []);
  return (
    <ArtistGraphContainer
      path={'/genre'}
      graphType={'Genre'}
      params={{
        genre: 'vapor soul',
        versionDate: ''
      }}
    />
  );
};
