

interface ToggleOuterProps {
  toggle: boolean;
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleOuter: React.FC<ToggleOuterProps> = ({ toggle, setToggle }) => {

  return (
    <div className="flex items-center justify-center mr-4">
      <p className={(toggle ? ' font-light ' : 'font-thin ') + 'text-xs mr-2'}>Outer Community</p>
      <label className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            id="toggleB"
            className="sr-only"
            checked={toggle}
            onChange={e => setToggle(e.target.checked)}
          />
          <div
            className={(toggle ? ' bg-primary ' : ' bg-gray-300 ') + ' bloc w-14 h-8 rounded-full'}
          ></div>
          <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
        </div>
      </label>
    </div>
  );
};

export default ToggleOuter;
