import React, { useState } from 'react';
import { useEffect } from 'react';
import calendar from '../assets/calendar.png';
import tick from '../assets/tick.png';
import arrowRight from '../assets/arrow-right.png';
import arrowLeft from '../assets/arrow-left.png';
import { useFetchVersions } from '../dataHandlers/versionHandler';
import { convertDate, getPageFromDate } from '../helpers/functions/getDatePages';
import { useComponentVisible } from '../helpers/hooks/ComponentVisibleHooks';
import { IDatePage } from './graph/GraphHooks';

interface DatePickerProps {
  currentVersion: string;
  setCurrentVersion: React.Dispatch<React.SetStateAction<string>>;
}

const DatePicker: React.FC<DatePickerProps> = ({ currentVersion, setCurrentVersion }) => {
  const { data: datePages } = useFetchVersions();

  const [currentPage, setCurrentPage] = useState<number>(1);
  //const datePagess: IDatePage = {1:['2021-20-1', '2021-20-1', '2021-20-1', '2021-20-1'],2:['2021', '199', '2021', '2020']}
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  useEffect(() => {
    if (datePages && !currentVersion) {
      setCurrentVersion(datePages[1][0]);
    }
  }, [datePages]);

  useEffect(() => {
    setCurrentPage(getPageFromDate(datePages, currentVersion));
  }, [isComponentVisible]);

  const increment = () => {
    if (currentPage < Object.keys(datePages).length) setCurrentPage(prev => prev + 1);
  };
  const decrement = () => {
    if (currentPage > 1) setCurrentPage(prev => prev - 1);
  };

  const select = (date: string) => {
    setCurrentVersion(date);
    setIsComponentVisible(false);
  };

  return (
    <div ref={ref} className="flex flex-col mx-1 ">
      <Date setShowDatePicker={setIsComponentVisible} date={currentVersion} />
      <div className="relative">
        <DateSuggestionPopUp
          isComponentVisible={isComponentVisible}
          datePages={datePages}
          currentPage={currentPage}
          currentVersion={currentVersion}
          select={select}
          increment={increment}
          decrement={decrement}
        />
      </div>
    </div>
  );
};
interface DateSuggestionPopUpProps {
  isComponentVisible: boolean;
  datePages: IDatePage;
  currentPage: number;
  currentVersion: string;
  select: (date: string) => void;
  increment: () => void;
  decrement: () => void;
}

const DateSuggestionPopUp: React.FC<DateSuggestionPopUpProps> = ({
  isComponentVisible,
  datePages,
  currentPage,
  currentVersion,
  select,
  increment,
  decrement
}) => {
  return isComponentVisible ? (
    <div className="absolute top-1 -right-10 -left-0 z-50  bg-white text-xs rounded shadow-md">
      <div className="flex justify-between  p-1 m-1">
        {currentPage !== 1 ? (
          <button type="button" className="rounded-full p-1 hover:bg-gray-100" onClick={decrement}>
            <img className={'h-4'} src={arrowLeft} />
          </button>
        ) : (
          <div></div>
        )}
        {currentPage !== Object.keys(datePages).length ? (
          <button type="button" className="rounded-full p-1 hover:bg-gray-100 " onClick={increment}>
            <img className={'h-4'} src={arrowRight} />
          </button>
        ) : (
          <div></div>
        )}
      </div>
      <div className="flex justify-between flex-wrap ">
        {Object.prototype.hasOwnProperty.call(datePages, currentPage) ? (
          datePages[currentPage].map(date => {
            return (
              <button
                key={date}
                type="submit"
                onClick={e => select(date)}
                className="flex justify-between hover:bg-gray-200 p-2 w-full font-light"
              >
                {convertDate(date)}
                {date === currentVersion ? (
                  <div>
                    <img className="h-3 m-auto" src={tick} alt="tick" />
                  </div>
                ) : (
                  <></>
                )}
              </button>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

interface DateProps {
  date: string;
  setShowDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
}

const Date: React.FC<DateProps> = ({ date, setShowDatePicker }) => {
  return (
    <button
      type="button"
      onClick={e => setShowDatePicker(true)}
      className="flex rounded-full my-auto border  p-2 px-4 bg-white hover:bg-gray-200"
    >
      <img className="m-auto h-3 w-3" src={calendar} alt="" />

      <p className="hidden lg:block mx-auto ml-2 text-2xs">{date ? convertDate(date) : ''}</p>
    </button>
  );
};

export default DatePicker;
