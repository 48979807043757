import React, { useEffect } from 'react';
import { ArtistGraphContainer } from '../../../components/graph/ArtistGraphContainer';
import { APP_NAME } from '../../../constants/global';

export const ConnectionContainer: React.FC = () => {
  useEffect(() => {
    document.title = `${APP_NAME} - Connection`;
  }, []);
  return (
    <ArtistGraphContainer
      path={'/connection'}
      graphType={'Connection'}
      params={{
        degreesOfFreedom: 1,
        outer: false,
        start: JSON.stringify(['6AyATGg7mDgBlZ4N5uNog0']),
        startName: 'SYML',
        end: JSON.stringify([
          '0MmnmsAuQKRFpo6vJElcaU',
          '4qWnlmXWuGv2TtuxtIWlJX',
          '4Ly0KABsxlx4fNj63zJTrF'
        ]),
        endName: 'Dermot Kennedy',
        versionDate: ''
      }}
    />
  );
};
