import React from 'react';
import { IGraphNode, ILegendPoint, ISummary } from '../graph/GraphHooks';
import Overview from './Overview';
import { TopArtists } from './TopArtists';
import TopGenres from './TopGenres';

interface summaryContainerProps {
  legend: ILegendPoint[];
  graphSummary: ISummary | undefined;
  nodes: IGraphNode[];
}

const SummaryContainer: React.FC<summaryContainerProps> = ({ graphSummary, nodes, legend }) => {
  return (
    <div className="flex justify-between mt-4">
      <Overview overview={graphSummary?.overview} />
      <TopGenres community={graphSummary?.community} />
      <TopArtists legend={legend} nodes={nodes} artists={graphSummary?.artists} />
    </div>
  );
};
export default SummaryContainer;
