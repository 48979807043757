import { useState } from 'react';
import minimize from '../../assets/minimize.png';
import minimizeDark from '../../assets/minimizeDark.png';
import expand from '../../assets/expand.png';
import expandDark from '../../assets/expandDark.png';

interface ExpandGraphProps {
  expandGraph: boolean;
  setExpandGraph: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExpandGraph: React.FC<ExpandGraphProps> = ({ expandGraph, setExpandGraph }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="absolute right-1 top-1 rounded-l h-5 w-5 flex items-center justify-center text-3xs">
      <button onClick={e => setExpandGraph(prev => !prev)}>
        {expandGraph ? (
          <img
            src={isHovered ? minimizeDark : minimize}
            onMouseOut={() => setIsHovered(false)}
            onMouseOver={() => setIsHovered(true)}
          />
        ) : (
          <img
            src={isHovered ? expandDark : expand}
            onMouseOut={() => setIsHovered(false)}
            onMouseOver={() => setIsHovered(true)}
          />
        )}
      </button>
    </div>
  );
};
export default ExpandGraph;
