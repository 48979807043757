import { useState } from 'react';
import searchbarIcon from '../../../assets/search-dark.png';
import DatePicker from '../../../components/DatePicker';

interface NettwerkSearchProps {
  search: any;
  setSearch: React.Dispatch<React.SetStateAction<any>>;
}

const NettwerkSearch: React.FC<NettwerkSearchProps> = ({ search, setSearch }) => {
  const [currentVersion, setCurrentVersion] = useState(search.versionDate);

  const onSubmitHandler = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    if (currentVersion) {
      setSearch({ degreesOfFreedom: 2, versionDate: currentVersion });
    }
  };

  return (
    <form onSubmit={onSubmitHandler} className="flex rounded-full my-auto mr-5 h-8">
      <DatePicker currentVersion={currentVersion} setCurrentVersion={setCurrentVersion} />
      <button className=" ml-6 mx-auto px-4 p-0 rounded text-center bg-white hover:bg-gray-200 ">
        <img className="h-3 w-3" src={searchbarIcon} />
      </button>
    </form>
  );
};
export default NettwerkSearch;
