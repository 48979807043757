import React from 'react';

const UnknownPage: React.FC = () => {
  return (
    <div>
      <h1>Unknown</h1>
    </div>
  );
};

export default UnknownPage;
