import { Document, Paragraph, TextRun, HeadingLevel, AlignmentType, Table, TableRow, TableCell, UnderlineType} from "docx";
import { IScheduleA } from "./ScheduleAHooks";

// Documents contain sections, you can have multiple sections per document, go here to learn more about sections
// This simple example will only contain one section



const generateScheduleA = (scheduleAInput: IScheduleA[]): Document =>{
    const children: (Table | Paragraph)[] = []
    for (const album of scheduleAInput){
        const table = new Table({
            columnWidths: [1000, 2000, 1000,2000, 2200,1000],
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph({children:[ new TextRun({text: "Track Number", bold:true})],alignment: AlignmentType.CENTER})]
                        }),
                        new TableCell({
                            children: [new Paragraph({children:[ new TextRun({text: "Title of Master", bold:true})],alignment: AlignmentType.CENTER})]
                        }),
                        new TableCell({
                            children: [new Paragraph({ children:[new TextRun({text: "Percentage Owned by Artist", bold:true})], alignment: AlignmentType.CENTER})]
                        }),
                        new TableCell({
                            children: [new Paragraph({ children:[new TextRun({text: "Third Party Co-Owners and Percentages", bold:true})], alignment: AlignmentType.CENTER})]
                        }),
                        new TableCell({
                            children: [new Paragraph({ children:[new TextRun({text: "Third Party Royalty Participant(s) entitled to a share of Artist's Net Receipts", bold:true})], alignment: AlignmentType.CENTER})]
                        }),
                        new TableCell({
                            children: [new Paragraph({ children:[new TextRun({text: "Third Party Participant's Share of Net Receipts", bold:true})], alignment: AlignmentType.CENTER})]
                        }),
                    ],
                }),
                ...tracks(album)
         
            ],
        });
        children.push(new Paragraph({text:""}))
        children.push(new Paragraph({
            children:[new TextRun({text: `${album.albumName}`, color:"#000000", underline: {type: UnderlineType.SINGLE }})],
          alignment: AlignmentType.LEFT
        }))
        children.push(new Paragraph({text:""}))
        children.push(table)
        

    }

    const doc = new Document({
        sections:[
            {
            children: [

                createHeading('Schedule "A"', HeadingLevel.HEADING_2),
                createHeading("The Existing Masters", HeadingLevel.HEADING_1),
                ...children
            ]
            }
        ]
    })
    return doc 

}

const tracks = (album:IScheduleA): TableRow[]=>{
    const arr: TableRow[] = [];
    album.albumTracks.map((track)=>{
        if(track.isChecked){
            arr.push(new TableRow({
            
                children: [
                    new TableCell({
                        children: [new Paragraph(`${track.trackNumber}`)],
                        
                    }),
                    new TableCell({ 
                        children: [new Paragraph(`${track.name}`)]
                        
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                        
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                        
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                        
                    }),
                    new TableCell({
                        children: [new Paragraph("")],
                        
                    })
                ]
                        
            }))
        }
    })

    return arr
}

const createHeading = (text: string, heading: HeadingLevel): Paragraph =>{
    return new Paragraph({
        children:[new TextRun({text: text, color:"#000000"})],
      alignment: AlignmentType.CENTER,
      heading: heading
    });
  }

export {generateScheduleA}

