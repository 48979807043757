import { IGraphNode, ISummaryArtist, ISummaryCommunity, ISummaryOverview } from "../../components/graph/GraphHooks"
import { objectInObjectArray, sortArrayOfObjects } from "./arrayOfObjects"

//returns a list of active artists
export const getActiveArtists = (nodes: IGraphNode[]) =>{
    const activeArtists = nodes.filter((element) => parseInt(element.lastReleases) > 0 )
    return activeArtists.map((item)=>{
        return item.id
    })
}
//returns a list of unsigned artists
export const getUnsignedArtists = (nodes: IGraphNode[]) =>{
    const unsignedArtists = nodes.filter((element) => element.unsigned === null ? element.id == element.label : element.unsigned )
    return unsignedArtists.map((item)=>{
        return item.id
    })
}
//returns a summary community genre
export const getCommunitySummary = (nodes: IGraphNode[]) =>{
    const genreDict : ISummaryCommunity[]= []
    
    for (let i = 0; i < nodes.length; i++) {
        for (let j = 0; j < nodes[i]["genres"].length; j++) {
            if(objectInObjectArray("genre",nodes[i].genres[j], genreDict )){
                const objIndex = genreDict.findIndex((obj => obj.genre == nodes[i].genres[j]))
                genreDict[objIndex].numberOfArtists = genreDict[objIndex].numberOfArtists + 1
            }else{
                genreDict.push({genre:nodes[i].genres[j], numberOfArtists: 1 })
            }
        }
        
    } 
    const newGenreDict = sortArrayOfObjects(genreDict, "numberOfArtists") 
    return newGenreDict
}

export const getArtistSummary = (nodes: IGraphNode[]) =>{
    const artistsDict: ISummaryArtist[] = []
    for (let i = 0; i < nodes.length; i++) {
        artistsDict.push({artist:nodes[i].id, followers: parseInt(nodes[i].followers) })
    }
    const newArtistDict = sortArrayOfObjects(artistsDict, "followers")
    return newArtistDict
    }  


export const getOverviewSummary = (nodes: IGraphNode[]) => {
    const overview: ISummaryOverview = {artists: nodes.length, avgFollowers:getAverageFollowers(nodes), avgReleases: getAverageReleases(nodes), totalFollowers: getTotalFollowers(nodes), avgPopularity: getAveragePopularity(nodes) }
    return overview

}

const getTotalFollowers =(nodes: IGraphNode[])=>{
    if (!nodes.length){
        return 0 
    }
    let totalFollowers = 0;
    for (let i = 0; i < nodes.length; i++) {
        totalFollowers = totalFollowers + parseInt(nodes[i].followers)
    }
    return totalFollowers
}

const getAverageFollowers = (nodes: IGraphNode[]) =>{
    if (!nodes.length){
        return 0 
    }
   
    let averageFollowers = 0;
    for (let i = 0; i < nodes.length; i++) {
        averageFollowers = averageFollowers + parseInt(nodes[i].followers)
    }
    return Math.floor(averageFollowers / nodes.length)
}
const getAverageReleases = (nodes: IGraphNode[]) =>{
    if (!nodes.length){
        return 0 
    }
    let averageReleases = 0;
    for (let i = 0; i < nodes.length; i++) {
        averageReleases = averageReleases + parseInt(nodes[i].lastReleases)
    }
    return Math.round((averageReleases / nodes.length)*10)/10
}

const getAveragePopularity = (nodes: IGraphNode[]) =>{
    if (!nodes.length){
        return 0 
    }
   
    let averagePopularity = 0;
    for (let i = 0; i < nodes.length; i++) {
        
        averagePopularity = averagePopularity + nodes[i].popularity
    }
    return Math.round((averagePopularity / nodes.length))
}

export const getClusterName = (node: IGraphNode) =>{
    return node.nonNettwerkAffinity === 0 ? 'Data unavailable' : node.nonNettwerkAffinity ? `${node.nonNettwerkAffinity.parent} (${node.nonNettwerkAffinity.subcluster})`: 'No cluster found';

}






