import { IDatePage } from "../../components/graph/GraphHooks"

export const getPages = (versions: {version: string, date: string}[])=>{
    let pageCount = 1
    let dateCount = 0
    const pages: IDatePage = {[pageCount]: []}

    versions.forEach(element =>{
        if(dateCount >= 5){
            dateCount = 0
            pageCount = pageCount + 1
            pages[pageCount] = []
        }
        pages[pageCount].push(element["date"])
        dateCount = dateCount + 1
    })

    return pages
}

const months = ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

export const convertDate = (date:string) => {
    const temp_date = date.split("-");
    return months[Number(temp_date[1]) - 1] + " " + temp_date[2]  + " " + temp_date[0];
}

export const getPageFromDate = (pages: IDatePage, currentVersion:string) =>{
    for(const page in pages){
        if(pages[page].includes(currentVersion)){
            return Number(page)
        }
    }
    return 1

}