import React from 'react';
import { IArtistDetail, INonNettwerkAffinity } from '../graph/GraphHooks';
import GenreItem from './GenreItem';
import { useLocation } from 'react-router';

interface ArtistDetailProps {
  artistDetail: IArtistDetail | undefined;
}

const ArtistDetail: React.FC<ArtistDetailProps> = ({ artistDetail }) => {
  const location = useLocation();
  const [showCluster, setShowCluster] = React.useState(false);

  return (
    <div className="relative border-b border-gray-200">
      <button
        onClick={e => {
          e.preventDefault();
          artistDetail ? (window.location.href = artistDetail.url) : (window.location.href = '');
        }}
        className="absolute right-0 top-1 py-1 md:px-2 lg:px-4 transform duration-500 ease-in-out text-xs hover:-translate-y-1 hover:scale-110 text-white bg-emerald rounded-l-lg"
      >
        Spotify
      </button>
      <div className="p-4 pt-8">

        <div
          className="bg-cover rounded-full h-20 w-20 border-2 border-black flex items-center justify-center m-auto"
          style={{
            backgroundImage: `url(${artistDetail ? artistDetail.img : ''})`
          }}
        ></div>
        <div className="text-center text-black">
          <div className="flex">
            <p
              style={{
                backgroundColor: artistDetail ? artistDetail.color : 'black'
              }}
              className="ml-auto mr-1 my-auto rounded-full h-2 w-2"
            ></p>
            <div className='mr-auto'>
              <a
                href={`${location.pathname}?artist=https://open.spotify.com/artist/${
                  artistDetail?.url.split(':')[2]
                }`}
                className="font-bold mr-auto hover:text-gray-10"
              >
                {artistDetail ? artistDetail.name : 'unknown'}
              </a>
            </div>
          </div>
          <div className='flex justify-center pb-2 text-xs font-medium italic'>
          <button className={`rounded-full p-0.5 px-2 hover:bg-gray-100 ${showCluster ? ((artistDetail?.nonNettwerkAffinity ? getClusterColor(artistDetail.nonNettwerkAffinity.subcluster) : 'border-black') + ' text-black border') : 'text-primary border-dotted border-primary border'}`} onClick={()=> setShowCluster(prev => !prev)}>
            { showCluster ? <Cluster nonNettwerkAffinity={artistDetail?.nonNettwerkAffinity} isNettwerk={artistDetail?.label.includes('Nettwerk') }/>
            : <span >Show cluster</span>}
          </button>
      </div>
          <p className="text-xs font-light">{artistDetail ? artistDetail.label : 'unknown'}</p>
          <div className="flex mt-4 justify-center text-2xs">
            <div>
              <p className="font-bold">
                
                {parseInt(artistDetail ? artistDetail.followers : '0').toLocaleString()}
                { artistDetail && <span className='pl-1'>{artistDetail.followerGrowth > 0 && '+' + artistDetail.followerGrowth + '%'}</span>}
              </p>
              <p>Followers</p>
            </div>
            <div className="mx-4">
              <p className="font-bold">{artistDetail ? artistDetail.releases : 'unknown'}</p>
              <p>
                Releases <span className="text-3xs">(in the last year)</span>
              </p>
            </div>
            <div className="">
              <p className="font-bold">{artistDetail ? artistDetail.popularity : '0'}</p>
              <p>Popularity</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap mt-6">
          {artistDetail ? (
            artistDetail.genres.map(genre => {
              return <GenreItem key={genre} genre={genre} />;
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

const getClusterColor = (subcluster: string) => {
  switch (subcluster) {
    case '0':
      return 'border-jade'
    case '1':
      return 'border-emerald'
    case '2':
      return 'border-sapphire'
    case '3':
      return 'border-amethyst'
    case '4':
      return 'border-rose'
    case '5':
      return 'border-sunstone'
    case '6':
      return 'border-amber'
    default:
      return 'border-black'
  }
}
const Cluster = ({ nonNettwerkAffinity, isNettwerk }: 
  {nonNettwerkAffinity: INonNettwerkAffinity, isNettwerk: boolean}) => {
    
    if (nonNettwerkAffinity === 0) {
      return  <span className='text-xs font-light italic'>Cluster data unavailable for date</span>
    }
    if(nonNettwerkAffinity){
      return <> <span>{nonNettwerkAffinity.parent} ({nonNettwerkAffinity.subcluster})</span><span className='font-bold'>{`${nonNettwerkAffinity.pct_affinity !== null ? (': ' + nonNettwerkAffinity.pct_affinity + '%'): ''}`}</span> </>
    }
    return <>No cluster found</>
  }
export default ArtistDetail;
