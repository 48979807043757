import React from 'react';

interface LoadingProps {
  className: string;
}

export const Loading: React.FC<LoadingProps> = ({ className }) => {
  return (
    <div
      style={{
        animation: 'spin 1.0s linear infinite',
        borderRadius: '50%',
        borderTopColor: '#6B9FFF'
      }}
      className={className}
    ></div>
  );
};

export default Loading;
