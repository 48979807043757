import React, { useState } from 'react';

interface ToolTipProps {
  mainText: React.ReactNode;
  toolTipText: string;
}

const ToolTip: React.FC<ToolTipProps> = ({ mainText, toolTipText }) => {
  const [visible, setVisible] = useState(false);
  const handleOnMouseOver = () => {
    setVisible(true);
  };
  const handleOnMouseOut = () => {
    setVisible(false);
  };

  return (
    <div
      onMouseOut={handleOnMouseOut}
      onMouseOver={handleOnMouseOver}
      className="relative inline-block"
    >
      {mainText}
      <span
        className={`absolute max-w-md bottom-5 -left-5 p-1 opacity-100 rounded shadow bg-black text-white font-semibold text-xs ${
          visible ? 'visible' : 'invisible'
        }`}
      >
        {toolTipText}
      </span>
    </div>
  );
};

export default ToolTip;
