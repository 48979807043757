import React from 'react';

interface GraphTitleProps {
  mappingType: string;
  subject: string;
}

//TODO:- different titles for different pages

const GraphTitle: React.FC<GraphTitleProps> = ({ mappingType, subject }) => {
  return (
    <div className="flex mr-2">
      <h1 className="my-auto text-black text-lg font-bold">{mappingType}</h1>
      {mappingType !== 'Nettwerk' ? (
        <>
          <h1 className="my-auto ml-2">|</h1>
          <h2 className="my-auto ml-2  text-black text-sm font-light">{subject}</h2>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default GraphTitle;
