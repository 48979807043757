import { getAlbumTracks, getArtistAlbums, getSeveralAlbums, getSeveralTracks } from "../../services/spotifyService";
import { chunkArray, objectInObjectArray } from "./arrayOfObjects";
import { toastError } from "./toasts";

export function getAllArtistAlbums(artist: string): Promise<Record<string, any>[]> {
    return new Promise((resolve, reject) => {
        const albums: Record<string, any>[] = [];
        const getAlbums = async (offset = 0) => {
            const result = await getArtistAlbums(artist, offset);
            
            if (result==="error") {
                toastError('Could not a establish a connection to Spotify, please try again!');
                reject("error");
                return;
            }
            const data = result.data;
            albums.push(...data.items);
            if (data.next) {
                getAlbums(offset + 50);
            } else {
                resolve(albums);
            }
        };
        getAlbums();
    });
}

export function getAllTracks(album: Record<string, any>): Promise<Record<string, any>[]> {
    return new Promise((resolve, reject) => {
        const tracks:any = [];
        const getTracks = async (offset = 0) => {
            const result = await getAlbumTracks(album.id, offset);
            if (result==="error") {
                toastError('Could not a establish a connection to Spotify, please try again!');
                reject("error");
                return;
            }
            const data = result.data;
            tracks.push(...data.items);
          
            if (data.next) {
                getTracks(offset + 50);
            } else {
                resolve(tracks);
            }
        };
        getTracks();
    });
}

export function getFullMetaDataAlbums(albums: Record<string, any>[]): Promise<Record<string, any>[]> {
    return new Promise((resolve, reject) => {
        const fullMetaDataAlbums: Record<string, any>[] = [];
        const getAlbums = async (offset = 0) => {
            const albumIds = albums.map(album => album.id).slice(offset, offset + 20).join(',');
            const result = await getSeveralAlbums(albumIds);
            if (result==="error") {
                toastError('Could not a establish a connection to Spotify, please try again!');
                reject("error");
                return;
            }
            const data = result.data;
            fullMetaDataAlbums.push(...data.albums);
            if (offset + 20 < albums.length) {
                getAlbums(offset + 20);
            } else {
                resolve(fullMetaDataAlbums);
            }
        };
        getAlbums();
    });
}

export async function getArtistTracks(artist: string): Promise<Record<string, any>[]> {
    const albums = await getAllArtistAlbums(artist);

    const fullMetaDataAlbums = await getFullMetaDataAlbums(albums);

    console.log(fullMetaDataAlbums);

    // Collect all track IDs from the albums
    const allTrackIds = (await Promise.all(fullMetaDataAlbums.map(album => getAllTracks(album))))
        .flat()
        .map(track => track.id);

    // Chunk the track IDs array into chunks of 50
    const trackIdChunks = chunkArray(allTrackIds, 50);

    // Fetch details for each chunk of track IDs
    const trackDetailsPromises = trackIdChunks.map(chunk => {
        const chunkedTrackIds = chunk.join(',');
        return getSeveralTracks(chunkedTrackIds);
    });

    const fetchedTrackDetails = await Promise.all(trackDetailsPromises);

    // Extract and concatenate track objects from each response
    const tracks = fetchedTrackDetails
        .filter(response => response !== 'error') // Assuming 'error' is your error flag
        .flatMap(response => response !== 'error' && response.data.tracks) // Adjust according to the actual structure of your response
        .filter(track => track); // Filter out any potential undefined or null entries
    return mergeAlbumsAndTracks(fullMetaDataAlbums, tracks);
}

function mergeAlbumsAndTracks(albums: Record<string, any>[], tracks: Record<string, any>[]): Record<string, any>[] {
    return tracks.map((track) => {
        const album = albums.filter((album) => track.album.id === album.id);
        return { ...track, album: album[0] };
    });
}
export function groupTracksByAlbum(tracks: Record<string, any>[]): Record<string, any>[] {
    const albums: Record<string, any>[] = [];

    tracks.forEach((track) => {
        const album = track.album;
        const albumIndex = albums.findIndex((a) => a.id === album.id);

        if (albumIndex === -1) {
            albums.push({ ...album, tracks: [track] });
        } else {
            albums[albumIndex].tracks.push(track);
        }
    });

    return albums.sort((a, b) => b.release_date.localeCompare(a.release_date));
}

export function getUniqueTrackNames(tracks: Record<string, any>[]): string[] {
    const trackNames: string[] = tracks.map((track) => track.name);

    return  Array.from(new Set<string>(trackNames).values());
}

