import { useState, useEffect, useContext } from 'react';
import { API, Auth } from 'aws-amplify';
import { toastError, toastSuccess } from '../helpers/functions/toasts';
import { AuthContext } from '../services/AuthProvider';

export const useFetchScheduleAHandler = (path: string, params: any) => {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);


  useEffect(() => {
    setIsLoading(true);
    setError(false);
    //test
    const fetchData = async (): Promise<void> => {
      if (params === null) {
        setIsLoading(false);
        return params;
      }
      try {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        
        const requestInfo = {
          body: { artistID: params.artistID, trackList: params.trackList, saveIsrcs: params.saveIsrcs, artistName: params.artistName },
          headers: {
            Authorization: token
          }
        };
        const res = await API.post('mappingApi', path, requestInfo);

        if (res) {
          setData(res);
          if (params.saveIsrcs) {
            toastSuccess('Catalogue value tableau report will be updated, please watch out for an email within 24 hours!', 10000);
          }
          else {
            toastSuccess('Schedule A created successfully');
          }
        } else {
          setError(true);
        }
      } catch (error) {
        console.log(error);
        toastError('Error creating schedule A, please try again');
        setError(true);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [params]);

  return {
    data,
    error,
    isLoading
  };
};
