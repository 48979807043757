import React from 'react';
import { ICheckbox } from './FilterHooks';

interface checkboxProps {
  onChangeFilter: (filterType: string, name: string, value: boolean) => void;
  filterType: string;
  checkbox: ICheckbox;
}

export const Checkbox: React.FC<checkboxProps> = ({ onChangeFilter, checkbox, filterType }) => {
  return (
    <div key={checkbox.name + filterType}>
      {
        <label key={checkbox.name} className="">
          <input
            className="form-checkbox border border-black text-primary h-5 w-5"
            type={'checkbox'}
            name={checkbox.name}
            checked={checkbox.isChecked}
            onChange={e => onChangeFilter(filterType, checkbox.name, e.target.checked)}
          />
          <span className="ml-2 text-sm font-light">{checkbox.name}</span>
        </label>
      }
    </div>
  );
};

interface AllCheckBoxProps {
  checkbox: ICheckbox;
  filterType: string;
  selectAll: (filterType: string, value: boolean) => void;
}

export const AllCheckBox: React.FC<AllCheckBoxProps> = ({ selectAll, checkbox, filterType }) => {
  return (
    <div>
      {
        <label key={checkbox.name}>
          <input
            type={'checkbox'}
            name={checkbox.name}
            checked={checkbox.isChecked}
            onChange={e => selectAll(filterType, e.target.checked)}
          />
          {checkbox.name}
        </label>
      }
    </div>
  );
};
