import React, { useEffect, useState } from 'react';
import './App.css';
import NavBar  from './components/NavBar';
import { CommunityContainer } from './views/Mapping/Community/CommunityContainer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { GenreContainer } from './views/Mapping/Genre/GenreContainer';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"
import { ConnectionContainer } from './views/Mapping/Connection/ConnectionContainer';
import { NettwerkContainer } from './views/Mapping/Nettwerk/NettwerkContainer';
import Amplify, { Analytics, API, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import Home from './views/Auth/Home'; 
import { AuthProvider } from './services/AuthProvider';
import PrivateRoute from './services/PrivateRoute';
import UnknownPage from './components/UnknownPage';
import { ScheduleABuilder } from './views/AnR/ScheduleA/ScheduleABuilderContainer';
import { Graph } from './components/graph/Graph';




Amplify.configure(awsconfig)
toast.configure() //configures toast to run on App DOM


// Auth.currentAuthenticatedUser()
//       .then(user => {
//         Analytics.autoTrack('pageView', {
//           enable: true,
//           attributes: {
//             userEmail:user.attributes.email
//         },
//           metrics:{
//             userEmail:user.attributes.email
//           }
//   })
// });

function App() {

  return (
    <AuthProvider>
      <Router>
          <Switch>
          {/* <Route exact path="/auth/login">
              <Login/> 
          </Route> */}
          <Route path="/auth" component={AuthRoutes} />
          <PrivateRoute path="/app/" component={MappingRoutes} />
          <PrivateRoute path="/anr" component={AnRRoutes}/>
          <Redirect to="/app/community" from="/" />
          <Route component={UnknownPage} />
          
          {/* <Route  path="/"  render={() => {return loggedIn ? <MappingRoutes/> : <Redirect to="/login"/>}}/> */}
          
            {/* <Redirect to="/" from="/" /> */}
            <title>{ "Communities" }</title>
          </Switch>
        </Router>
      </AuthProvider>
  );
}

const MappingRoutes = () => {
  return (
    <div className='flex h-screen overflow-y-hidden'>
      <NavBar/>
      <Switch>
        <Route exact path="/app/community">
          <CommunityContainer/>
        </Route>
        <Route exact path="/app/connection">
          <ConnectionContainer/>
        </Route>
        <Route exact path="/app/genre">
          <GenreContainer/>
        </Route>
        <Route exact path="/app/nettwerk">
          <NettwerkContainer/>
        </Route>
      </Switch>
    </div>
    
  );
 
};

const AuthRoutes = () => {
  return (
    <Switch>
      <Route exact path="/auth/login">
        <Home isLogin={true}/>
      </Route>
      <Route exact path="/auth/signup">
        <Home isLogin={false}/>
      </Route>
      <Redirect to="/auth/login" from="/" />
    </Switch>
  );
};

const AnRRoutes = () => {
  return (
    <Switch>
      <Route exact path="/anr/scheduleABuilder">
        <ScheduleABuilder/>
      </Route>
    </Switch>
  );
}; 
export default App;
