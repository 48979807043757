import React, { useState } from 'react';
import { countValueInArrayOfObjects } from '../../helpers/functions/arrayOfObjects';
import { IGraphNode, ILegendPoint, ISummaryArtist } from '../graph/GraphHooks';
import SummaryPoint from './SummaryPoint';
import SummaryTitle from './SummaryTitle';
import downloadArrow from '../../assets/download-arrow.png';
import downloadArrowActive from '../../assets/download-arrow-active.png';
import { CSVLink } from 'react-csv';
import ToolTip from '../toolTip/ToolTip';
import { getClusterName } from '../../helpers/functions/graphHelpers';

interface TopArtistsProps {
  legend: ILegendPoint[];
  artists: ISummaryArtist[] | undefined;
  nodes: IGraphNode[];
}

export const TopArtists: React.FC<TopArtistsProps> = ({ artists, nodes, legend }) => {
  return (
    <div className="summary-card ml-3  bg-primary">
      <div className="flex justify-between">
        <SummaryTitle title={'Top artists'} />
      </div>
      {artists ? (
        [
          artists.length > 0 ? (
            <SummaryPoint
              key={artists[0].artist}
              amount={artists[0].artist}
              description={artists[0].followers.toLocaleString() + ' followers'}
            />
          ) : (
            <></>
          ),
          artists.length > 1 ? (
            <SummaryPoint
              key={artists[1].artist}
              amount={artists[1].artist}
              description={artists[1].followers.toLocaleString() + ' followers'}
            />
          ) : (
            <></>
          ),
          artists.length > 2 ? (
            <SummaryPoint
              key={artists[2].artist}
              amount={artists[2].artist}
              description={artists[2].followers.toLocaleString() + ' followers'}
            />
          ) : (
            <></>
          )
        ]
      ) : (
        <></>
      )}
    </div>
  );
};

interface TopArtistsListViewProps {
  filteredNodes: Map<string, IGraphNode>;
  legend: ILegendPoint[];
  sortedNodes: IGraphNode[];
  onClick: (nodeId: string) => void;
}

export const TopArtistsListView: React.FC<TopArtistsListViewProps> = ({
  legend,
  sortedNodes,
  filteredNodes,
  onClick
}) => {
  const uniqueColors = sortedNodes
    .map(node => node.color)
    .filter((value, index, self) => self.indexOf(value) === index);
  const cleanLegend = legend.filter(legendItem => uniqueColors.includes(legendItem.color));

  const nodesWithCluster = sortedNodes.map(node => (
    {
      ...node,
      nonNettwerkAffinity: getClusterName(node)
    }
  ))

  const [src, setSrc] = useState(downloadArrow);

  return (
    <div className="w-full p-4">
      <CSVLink className="" filename={'mapArtists.csv'} data={nodesWithCluster}>
        <button
          onMouseOut={e => setSrc(downloadArrow)}
          onMouseOver={e => setSrc(downloadArrowActive)}
          className="flex bg-white text-gray-600 p-2 px-4 hover:text-green-400 hover:border-green-400 mr-auto mb-4"
        >
          <img key={src} className="h-3 w-3 mr-2 my-auto" src={src} alt="Export Mapping Data" />
          <p className="text-xs">Export</p>
        </button>
      </CSVLink>
      <div className="flex space-x-2">
        {cleanLegend.map(legendItem => (
          <div className="overflow-x-visible">
            <h3 className="mb-2 capitalize p-1 rounded text-white flex bg-primary items-center font-light">
              {legendItem.description}
              <span className="ml-auto inline-block bg-white text-black px-2 rounded">
                {countValueInArrayOfObjects(sortedNodes, 'color', legendItem.color)}
              </span>
            </h3>
            <div className="h-full">
              {sortedNodes.map((node, idx) =>
                node.color === legendItem.color ? (
                  <ArtistColor
                    key={node.id}
                    nodeId={node.id}
                    followerGrowth={node.followerGrowth}
                    filtered={filteredNodes.has(node.id)}
                    handleClick={onClick}
                    nettwerk={node.fontColor === 'blue'}
                    followers={parseInt(node.followers).toLocaleString()}
                    popularity={node.popularity.toLocaleString()}
                    color={node.color}
                    name={node.id}
                  />
                ) : (
                  <></>
                )
              )}
            </div>
          </div>
        ))}
      </div>
      <div></div>
    </div>
  );
};

interface ArtistColorProps {
  color: string;
  nettwerk: boolean;
  name: string;
  nodeId: string;
  filtered: boolean;
  followers: string;
  followerGrowth: number;
  popularity: string;
  handleClick: (nodeId: string) => void;
}
const ArtistColor: React.FC<ArtistColorProps> = ({
  color,
  nettwerk,
  name,
  followers,
  popularity,
  nodeId,
  filtered,
  followerGrowth,
  handleClick
}) => {
  return (
    <button
      onClick={() => handleClick(nodeId)}
      className={`flex my-1 p-2 rounded hover:bg-gray-90 w-full pr-8 pl-4 ${
        filtered ? 'opacity-30' : ''
      }`}
    >
      <div className="w-2 h-2 mr-1 my-auto ">
        <p style={{ backgroundColor: color }} className="rounded-full h-2 w-2 min-w-full"></p>
      </div>
      <h3
        className={`font-light mr-1 whitespace-nowrap ${
          nettwerk ? 'text-blue-800 font-bold' : ''
        } `}
      >
        {name}
      </h3>

      <div className="font-thin whitespace-nowrap ml-auto">
        <ToolTip mainText={<span>{followers} <span className='font-normal'>{followerGrowth>0 ? '+' + followerGrowth + '%' : ''}</span></span>} toolTipText="Followers" />
        <> / </>

        <ToolTip mainText={popularity} toolTipText="Popularity" />
      </div>
    </button>
  );
};
