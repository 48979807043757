import React from 'react';
import community from '../assets/community.png';
import communityActive from '../assets/community-active.png';
import connection from '../assets/connection.png';
import connectionActive from '../assets/connection-active.png';
import genre from '../assets/genre.png';
import genreActive from '../assets/genre-active.png';
import nettwerk from '../assets/nettwerk-logo.png';
import nettwerkActive from '../assets/nettwerk-logo-active.png';
import signOutImg from '../assets/sign-out.png';
import { Link } from 'react-router-dom';
import { useAuth } from '../services/AuthHooks';

const NavBar: React.FC = () => {
  const { signOut } = useAuth();

  const currentItem = window.location.pathname.slice(1);

  return (
    <nav className="flex flex-col bg-white w-1/12 p-1 ">
      <ul className="mt-10 ">
        <li>
          <NavBarItem
            img={community}
            activeImg={communityActive}
            pageName={'community'}
            currentItem={currentItem}
          />
        </li>
        <li>
          <NavBarItem
            img={connection}
            activeImg={connectionActive}
            pageName={'connection'}
            currentItem={currentItem}
          />
        </li>
        <li>
          <NavBarItem
            img={genre}
            activeImg={genreActive}
            pageName={'genre'}
            currentItem={currentItem}
          />
        </li>
        <li>
          <NavBarItem
            img={nettwerk}
            activeImg={nettwerkActive}
            pageName={'nettwerk'}
            currentItem={currentItem}
          />
        </li>
      </ul>
      <div className="flex mt-auto mb-20">
        <button className="mx-auto  w-7" onClick={signOut}>
          <img src={signOutImg} alt="sign out" />
        </button>
      </div>
    </nav>
  );
};

interface NavBarItemProps {
  img: string;
  activeImg: string;
  currentItem: string;
  pageName: string;
}

const NavBarItem: React.FC<NavBarItemProps> = ({ img, activeImg, currentItem, pageName }) => {
  const styles = {
    nettwerkImg: pageName == 'nettwerk' ? 'w-14' : ' w-10',
    active: ''
    // active: currentItem.includes(pageName) ? ' bg-primary bg-opacity-75 shadow rounded-lg': ''
  };
  return (
    <div className="flex mb-7">
      <Link className="mx-auto " to={`/app/${pageName}`}>
        <button className={'p-2'}>
          <img
            key={currentItem.includes(pageName) ? activeImg : img}
            src={currentItem.includes(pageName) ? activeImg : img}
            className={styles.nettwerkImg}
          />
        </button>
      </Link>
    </div>
  );
};

export default NavBar;
