import { toast } from 'react-toastify';

//Toasts
export const toastError = (error: string) => {
  toast.error(error, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
};

export const toastSuccess = (success: string, autoClose: number = 5000) => {
  toast.success(success, {
    position: 'top-right',
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
};
