import { useEffect, useState } from 'react';
import { APP_NAME } from '../../constants/global';
import { useAuth } from '../../services/AuthHooks';

const LoginForm: React.FC = () => {
  useEffect(() => {
    document.title = `${APP_NAME} - Login`;
  }, []);

  const { signIn } = useAuth();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    signIn(username, password);
  };

  return (
    <form onSubmit={onSubmit}>
      <h1 className="text-2xl font-bold text-center mb-8">Login</h1>
      <h3 className="mb-1">username</h3>
      <input
        className="w-full mb-4 rounded-full py-2 px-6 p-2 border border-gray-200"
        value={username}
        onChange={e => setUsername(e.target.value)}
        placeholder="Your Username"
        type="username"
        id="username"
        name="username"
      />

      <h3 className="mb-1">password</h3>
      <input
        className="w-full mb-4 rounded-full py-2 px-6 p-2 border border-gray-200"
        value={password}
        onChange={e => setPassword(e.target.value)}
        placeholder="Your Password"
        type="password"
        id="password"
        name="password"
      />

      <div className="flex justify-center text-white ">
        <button className=" py-2 w-6/12 font-bold rounded-full bg-primary hover:shadow-2xl ">
          Login
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
