import React, { useState } from 'react';
import ConnectionSearch from '../../views/Mapping/Connection/ConnectionSearch';
import CommunitySearch from '../../views/Mapping/Community/CommunitySearch';
import NettwerkSearch from '../../views/Mapping/Nettwerk/NettwerkSearch';
import GenreSearch from '../../views/Mapping/Genre/GenreSearch';

interface InputSearchProps {
  search: any;
  setSearch: React.Dispatch<React.SetStateAction<any>>;
  graphType: string;
}

export const InputSearch: React.FC<InputSearchProps> = ({ graphType, search, setSearch }) => {
  switch (graphType) {
    case 'Connection':
      return <ConnectionSearch search={search} setSearch={setSearch} />;
    case 'Community':
      return <CommunitySearch search={search} setSearch={setSearch} />;
    case 'Nettwerk':
      return <NettwerkSearch search={search} setSearch={setSearch} />;
    case 'Genre':
      return <GenreSearch search={search} setSearch={setSearch} />;
  }
};
