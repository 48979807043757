import React from 'react';
import { IScheduleA, IScheduleAAlbumTracks, useScheduleA } from './ScheduleAHooks';
import ScheduleASearchArtist from './ScheduleASearchArtist';
import Loading from '../../../components/Loading';
import Modal from '../../../components/Modal';
import { Explanation } from './Explanation';
import { useComponentVisible } from '../../../helpers/hooks/ComponentVisibleHooks';
import { Disclosure } from '@headlessui/react';
import downArrow from '../../../assets/down-arrow.png';
import upArrow from '../../../assets/up-arrow.png';
import clsx from 'clsx';

export const ScheduleABuilder: React.FC = () => {
  const {
    scheduleA,
    artist,
    dataIsLoading,
    trackListIsLoading,
    trackList,
    ref,
    isComponentVisible,
    saveIsrcs,
    albumList,
    setSaveIsrcs,
    setIsComponentVisible,
    generateFiles,
    setTrackList,
    setArtist,
    setSearch,
    atLeastOneTrackSelected
  } = useScheduleA();

  const {
    ref: instructionsRef,
    isComponentVisible: instructionsIsComponentVisible,
    setIsComponentVisible: setInstructionsIsComponentVisible
  } = useComponentVisible(false);

  const handleIsComponentVisible = (visible: boolean) => {
    setInstructionsIsComponentVisible(visible);
  };

  const formValidation = (): boolean => {
    return (artist && trackList) !== null && atLeastOneTrackSelected(trackList);
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formValidation()) {
      setSearch({
        artistID: artist.id,
        trackList: trackList,
        artistName: artist.name,
        saveIsrcs: saveIsrcs,
      });
    }
  };

  const handleTrackChange = (isChecked: boolean, isrc: string) => {
    const trackIndex = trackList?.findIndex((t) => t.isrc === isrc);
    if (trackIndex !== undefined && trackList) {
      const newTrackList = [...trackList];
      newTrackList[trackIndex].isChecked = isChecked;
      setTrackList(newTrackList);
    }
  }

  const handleSelectAllOnAlbum = (checked: boolean, albumId: string) => {
    const album = albumList?.find((a) => a.id === albumId);
    if (album) {
      const newTrackList = trackList?.map((t) => {
        if (album.tracks.find((track: any) => track.external_ids.isrc === t.isrc)) {
          return { ...t, isChecked: checked };
        }
        return t;
      });
      setTrackList(newTrackList);
    }
  };

  const handleSelectAll = (checked: boolean) => {
    const newTrackList = trackList?.map((t) => {
      return { ...t, isChecked: checked };
    });
    setTrackList(newTrackList);
  }

  const isAllSelected = trackList?.every((t) => t.isChecked);

  const isAllSelectedOnAlbum = (albumId: string) => {
    const album = albumList?.find((a) => a.id === albumId);
    if (album) {
      const selectedTracks = album.tracks.filter((track: any) => {
        return trackList?.find((t) => t.isrc === track.external_ids.isrc && t.isChecked);
      });
      return selectedTracks.length === album.tracks.length;
    }
    return false;
  }

  const getNumberOfTracksSelectedOnAlbum = (albumId: string) => {
    const album = albumList?.find((a) => a.id === albumId);
    if (album) {
      const selectedTracks = album.tracks.filter((track: any) => {
        return trackList?.find((t) => t.isrc === track.external_ids.isrc && t.isChecked);
      });
      return selectedTracks.length;
    }
    return 0;
  }



  return (
    <form
      onSubmit={handleSubmit}
      className="mt-8 relative rounded-lg min-h-screen md:p-16"
    >
      <Modal
        refObj={instructionsRef}
        show={instructionsIsComponentVisible}
        children={<Explanation onClickComponentVisible={handleIsComponentVisible} />}
      />
      <button
        type="button"
        className="block underline p-2 rounded hover:underline text-lg"
        onClick={() => handleIsComponentVisible(true)}
      >
        Read Me
      </button>
      <div className="flex">
        <h1 className="p-2 mx-auto text-black font-light text-3xl">Schedule "A" Creator</h1>
      </div>

      <div className=" flex justify-between bg-white mb-8">
        <div className="fixed right-4 top-4">
          {dataIsLoading || trackListIsLoading ? <Loading className="h-16 w-16 border-8" /> : <></>}{' '}
        </div>
        <div className=" flex bg-white fixed right-0 left-0 bottom-0">
          <button
            type="submit"
            className={
              (formValidation()
                ? 'bg-green-400 hover:shadow text-white'
                : ' border border-gray-200 text-gray-300 hidden') + ' w-full p-4  whitespace-nowrap'
            }
            disabled={!formValidation()}
          >
            Generate Schedule A
          </button>
        </div>
      </div>
      <div className="">
      <div className="m-4 mt-8">
          <StepHeader
            onChange={e => setSaveIsrcs(e)}
            step={<span className="">1. Do you want to update the catalogue value tableau report (used by A&R)?</span>}
            isChecked={saveIsrcs}
          />
  
      </div>
      <div className="m-4 mt-8">
          <StepHeader
            step={<span className="">2. Who is the artist?</span>}
          />
          <div className="flex">
            <div className="w-full my-auto">
              <ScheduleASearchArtist
                placeholder="Enter artist"
                artist={artist}
                setArtist={setArtist}
              />
            </div>
            <div className="w-1/4 my-auto ">
              <div
                className={`bg-cover rounded-full h-36 w-36  m-auto `}
                style={{
                  backgroundImage: `url(${artist ? artist.images[0].url : ''})`
                }}
              ></div>
            </div>
          </div>
        </div>
        
       
        {albumList ? (
          <div className='divide-y space-y-6'>
            <div className='flex space-x-2 items-center'>
            <h2>{albumList?.length} releases </h2>
            <input
                className="form-checkbox border border-gray-400 rounded text-green-400 h-5 w-5"
                type={'checkbox'}
                checked={isAllSelected}
                onChange={e =>{  handleSelectAll(e.target.checked)}}
              />
            </div>
         
            {
            albumList.map((album, idx) => {
              return <Disclosure key={album.id}>
                 {({ open }) => (
                <div className='p-2 py-6 w-full'>
                <Disclosure.Button className='w-full'> 
                <div className='flex justify-between w-full'>
                  <div className='flex space-x-4 w-full'>
                    <img className='h-32 w-32' src={album.images[0].url} alt={album.name} />
                    <div className='text-left w-full'>
                      <div className='flex space-x-2 items-center justify-between'>
                        <h1 className='text-xl font-bold'>{album.name}</h1>
                        <div className='flex space-x-2 items-center'>
                          <p>{getNumberOfTracksSelectedOnAlbum(album.id)} / {album.total_tracks }</p>
                          <input
                            className="form-checkbox border border-gray-400 rounded text-green-400 h-5 w-5"
                            type={'checkbox'}
                            checked={isAllSelectedOnAlbum(album.id)}
                              onClick={e => e.stopPropagation()}
                            onChange={e =>{  handleSelectAllOnAlbum(e.target.checked, album.id)}}
                          />
                        </div>
                      </div>
                      <p>{album.album_type} &bull; {album.release_date} &bull; {album.total_tracks} songs</p>
                      <p>{album.label} (US label)</p>
                    </div>
                  
                    </div>
                    <img alt='' className="h-5 w-5 my-auto mx-2" src={open ? downArrow : upArrow} />
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel className="text-gray-500">
                  <ol className=''>
                    {album.tracks.map((track: any) => <li key={track.id} className='flex space-x-4 items-center'>
                      <p>{track.track_number}.</p>
                      <div className='space-x-2 p-2 flex items-center'>
                        <input
                          className="form-checkbox border border-gray-400 rounded text-green-400 h-5 w-5"
                          type={'checkbox'}
                          checked={trackList?.find((t) => t.isrc === track.external_ids.isrc)?.isChecked}
                          onChange={e => handleTrackChange(e.target.checked, track.external_ids.isrc)}
                          />
                            <div>
                              <h2 className={clsx(trackList?.find((t) => t.isrc === track.external_ids.isrc)?.isChecked ? 'text-black' : 'text-gray-20', 'text-lg font-bold')}>{track.name}</h2>
                              <p className='text-sm'>{track.artists.map((artist: any) => artist.name).join(', ')}</p>
                            </div>
                        </div>
                      </li>
                    )}
                    </ol>
                    
                </Disclosure.Panel>
                 
                </div>
                   )}
                </Disclosure>
            })
          }</div>
        ) : (
          null
        )}
        <div className="relative">
          <Modal
            refObj={ref}
            show={isComponentVisible}
            children={
              <ScheduleAPopUp
                albums={scheduleA}
                generateFiles={generateFiles}
                setIsComponentVisible={setIsComponentVisible}
              />
            }
          />
        </div>
      </div>
    </form>
  );
};

interface StepHeaderProps {
  onChange?: (isChecked: boolean) => void
  step: React.ReactNode;
  isChecked?: boolean;
}
const StepHeader: React.FC<StepHeaderProps> = ({ step, isChecked, onChange }) => {
  return (
    <div className="flex">
      <h2 className="text-xl lg:text-xl font-medium  ">{step}</h2>
      {isChecked !== undefined ?     <label className="mx-8 ml-12 m-auto">
        <input
          className="form-checkbox border border-black text-green-400  h-8 w-8"
          type={'checkbox'}
          onChange={e => onChange(e.target.checked)}
          readOnly={!onChange}
          checked={isChecked}
        />
      </label> : null}
  
    </div>
  );
};

interface ScheduleAPopUpProps {
  albums: IScheduleA[];
  generateFiles: () => void;
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScheduleAPopUp: React.FC<ScheduleAPopUpProps> = ({
  albums,
  setIsComponentVisible,
  generateFiles
}) => {
  const atLeastOneTrackNotSelected = (albumTracks: IScheduleAAlbumTracks[]) => {
    if (albumTracks) {
      for (const track of albumTracks) {
        if (!track.isChecked) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <div className="fixed overflow-y-scroll overflow-x-hidden right-40 left-40 bottom-40 top-20 rounded shadow-lg bg-white">
      <h3 className="sticky w-full top-0 bg-red-500 p-4 text-white ">
        {' '}
        You may have missed some tracks, are you sure you want to continue?{' '}
        <button className="underline " onClick={e => generateFiles()}>
          Yes
        </button>{' '}
        or{' '}
        <button
          className="underline"
          onClick={e => {
            setIsComponentVisible(false);
          }}
        >
          no
        </button>{' '}
      </h3>
      <div className=" flex flex-wrap w-full m-8">
        {albums.map((album, albIdx) => {
          return atLeastOneTrackNotSelected(album.albumTracks) ? (
            <ol key={albIdx} className="w-1/3 my-4 list-decimal">
              <span className="font-bold">
                {album.albumName} <span className="font-light"> ({album.albumType})</span>
              </span>
              {album.albumTracks.map((track, trackIdx) => {
                return (
                  <li key={trackIdx + albIdx} className="ml-8 font-light">
                    {track.name} -{' '}
                    <span className="italic font-thin">
                      {track.isChecked ? 'selected' : 'not selected'}
                    </span>
                  </li>
                );
              })}
            </ol>
          ) : (
            <></>
          );
        })}
      </div>
      <div></div>
    </div>
  );
};
