import React, { useEffect, useState } from 'react';
import FilterGenre from '../settings/GenreItem';
import { useFilter, ICheckbox, IFilter } from '../settings/FilterHooks';
import { objectInFilter, sortFilters } from '../../helpers/functions/filterHelpers';
import {
  getActiveArtists,
  getArtistSummary,
  getClusterName,
  getCommunitySummary,
  getOverviewSummary,
  getUnsignedArtists
} from '../../helpers/functions/graphHelpers';
import { useFetchGraphData } from '../../dataHandlers/graphHandler';

export type INonNettwerkAffinity = 
{  parent: string;
  pct_affinity: number;
  subcluster: string } | null | 0;

export interface IGraphNode {
  id: string;
  fontColor: string;
  description?: string;
  uri: string;
  followers: string;
  imgUrl: string;
  unsigned: boolean | null;
  popularity: number;
  color: string;
  size: number;
  linkColor: string;
  label: string;
  genres: string[];
  lastReleases: string;
  opacity?: number;
  followerGrowth?: number;
  nonNettwerkAffinity:INonNettwerkAffinity;
}
export interface IDatePage {
  [page: number]: string[];
}

export interface ISummaryCommunity {
  genre: string;
  numberOfArtists: number;
}

export interface ISummaryArtist {
  artist: string;
  followers: number;
}
export interface ISummaryOverview {
  artists: number;
  avgFollowers: number;
  avgReleases: number;
  totalFollowers: number;
  avgPopularity: number;
}

export interface ISummary {
  overview: ISummaryOverview;
  community: ISummaryCommunity[];
  artists: ISummaryArtist[];
}
export interface ILegendPoint {
  color: string;
  description: string;
}

export interface IGraphData {
  legend: ILegendPoint[];
  nodes: IGraphNode[];
  links: (
    | {
        source: string;
        target: string;
        strokeWidth: number;
      }
    | {
        source: string;
        target: string;
      }
  )[];
}

export interface IArtistDetail {
  img: string;
  name: string;
  url: string;
  popularity: number;
  label: string;
  releases: string;
  followers: string;
  genres: string[];
  color: string;
  followerGrowth: number;
  nonNettwerkAffinity: INonNettwerkAffinity;

}

export const useGraph = (path: string, params: any) => {
  const {
    filters,
    setFilters,
    onChangeFilter,
    resetFilters,
    selectAll,
    checkFilterConditions,
    followersScale,
    onChangeFilterFollowerScale,
    setUnsignedArtists,
    setActiveArtists
  } = useFilter();

  const [search, setSearch] = useState<any>(params);

  const {
    data: incomingGraphData,
    isLoading: graphDataIsLoading,
    error
  } = useFetchGraphData(path, search);

  const [data, setData] = useState<IGraphData>();
  const [graphSummary, setGraphSummary] = useState<ISummary>();
  const [artistDetail, setArtistDetail] = useState<IArtistDetail>();
  const [filteredNodes, setFilteredNodes] = useState<Map<string, IGraphNode>>(new Map());

  useEffect(() => {
    if (!graphDataIsLoading && incomingGraphData) {
      setFilteredNodes(new Map());
      setData(incomingGraphData);
      setIncomingFilters(incomingGraphData);
      setIncomingSummary(incomingGraphData);
      setIncomingStartArtist(incomingGraphData);
    }
  }, [graphDataIsLoading]);

  //sets up filters

  const setIncomingStartArtist = (data: IGraphData) => {
   
    if (data.nodes.length >= 1)
      setArtistDetail({
        img: data['nodes'][0]['imgUrl'],
        name: data['nodes'][0]['id'],
        url: data['nodes'][0]['uri'],
        label: data['nodes'][0]['label'],
        popularity: data['nodes'][0]['popularity'],
        releases: data['nodes'][0]['lastReleases'],
        followers: data['nodes'][0]['followers'],
        genres: data['nodes'][0]['genres'],
        color: data['nodes'][0]['color'],
        followerGrowth: data['nodes'][0]['followerGrowth'],
        nonNettwerkAffinity: data['nodes'][0]['nonNettwerkAffinity']
      });
  };

  const setIncomingFilters = (data: IGraphData) => {
    const newFilter: IFilter[] = [];
    for (let i = 0; i < data.nodes.length; i++) {
      const currentNode = data.nodes[i];
      const cluster = getClusterName(currentNode);
      for (let g = 0; g < currentNode.genres.length; g++) {
        //set genre filter
        if (
          !objectInFilter(
            { filterType: 'genre', name: currentNode.genres[g], value: true },
            newFilter
          )
        ) {
          newFilter.push({
            filterType: 'genre',
            name: currentNode.genres[g],
            value: true
          });
        }
      }
      if (
        !objectInFilter({ filterType: 'label', name: currentNode.label, value: true }, newFilter)
      ) {
        //set label filter
        newFilter.push({
          filterType: 'label',
          name: currentNode.label,
          value: true
        });
      }
      //set artist filter
      if (
        !objectInFilter({ filterType: 'artist', name: currentNode.id, value: true }, newFilter)
      ) {
        newFilter.push({
          filterType: 'artist',
          name: currentNode.id,
          value: true
        }); 
      }
      //set cluster filter
      if (
        !objectInFilter({ filterType: 'cluster', name: cluster, value: true }, newFilter)
      ) {
        newFilter.push({
          filterType: 'cluster',
          name: cluster,
          value: true
        }); 
      }
    }
    newFilter.push({
      filterType: 'unSigned',
      name: 'not signed',
      value: false
    }); //add unSigned
    newFilter.push({
      filterType: 'active',
      name: 'active in the last year',
      value: false
    }); //add active
    // var newFilter = newFilter.concat(data.nodes.map(node=> node.color).filter((value, index, self) => self.indexOf(value) === index).map(color => ({filterType: 'color',name: data.legend[getIndexOfObject(data.legend, 'color', color)].description, value:true})))
    const sortedFilter = sortFilters(newFilter);
    data.legend.map(legend =>
      sortedFilter.push({
        filterType: 'community',
        name: legend.description,
        value: true
      })
    );
    setFilters(sortedFilter);
  };

  const setIncomingSummary = (data: IGraphData) => {
    setActiveArtists(getActiveArtists(data.nodes));
    setUnsignedArtists(getUnsignedArtists(data.nodes));
    setGraphSummary({
      overview: getOverviewSummary(data.nodes),
      artists: getArtistSummary(data.nodes),
      community: getCommunitySummary(data.nodes)
    });
  };

  useEffect(() => {
    if (data) {
      filterGraph(data.nodes);
      updateSummary(data.nodes);
    }
  }, [filters, followersScale]);

  const updateSummary = (nodes: IGraphNode[]) => {
    if (data) {
      const newNodes = [];
      for (let i = 0; i < nodes.length; i++) {
        if (checkFilterConditions(nodes[i])) {
          newNodes.push(nodes[i]);
        }
      }

      setGraphSummary({
        overview: getOverviewSummary(newNodes),
        artists: getArtistSummary(newNodes),
        community: getCommunitySummary(newNodes)
      });
    }
  };

  const filterGraph = (nodes: IGraphNode[]) => {
    if (data) {
      const newNodes: IGraphNode[] = [];
      const newFilteredNodes = new Map();
      for (let i = 0; i < nodes.length; i++) {
        const isFiltered = checkFilterConditions(nodes[i]);
        if (!isFiltered) {
          newFilteredNodes.set(nodes[i].id, nodes[i]);
        }
        newNodes.push(isFiltered ? { ...nodes[i], opacity: 1 } : { ...nodes[i], opacity: 0.2 });
      }
      setFilteredNodes(newFilteredNodes);
    }
  };

  return {
    data,
    filters,
    followersScale,
    artistDetail,
    graphSummary,
    graphDataIsLoading,
    error,
    search,
    filteredNodes,
    setFilteredNodes,
    setSearch,
    onChangeFilter,
    onChangeFilterFollowerScale,
    setArtistDetail,
    resetFilters,
    selectAll
  };
};
