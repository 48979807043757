import { useState } from 'react';
import { isFilterActiveType, objectInFilter } from '../../helpers/functions/filterHelpers';
import { IGraphNode } from '../graph/GraphHooks';
import { getClusterName } from '../../helpers/functions/graphHelpers';

export interface ICheckbox {
  name: string;
  isChecked: boolean;
}

export interface IFilter {
  filterType: string;
  name: string;
  value: boolean;
}

export const useFilter = () => {
  //main Filters
  const [filters, setFilters] = useState<IFilter[]>([]);

  //follower filter
  const [followersScale, setFollowersScale] = useState({
    min: 0,
    max: 100000
  });

  //added below to fix the issue with initialZoom BUG
  //if initialZoom bug is fixed then we can move config back into
  const [isFiltered, setIsFiltered] = useState(false);

  //active artist list
  const [activeArtists, setActiveArtists] = useState<string[]>([]);
  //unsigned artist list
  const [unsignedArtists, setUnsignedArtists] = useState<string[]>([]);

  const onChangeFilterFollowerScale = (value: { min: number; max: number }) => {
    setIsFiltered(true);
    setFollowersScale(value);
  };
  const onChangeFilter = (filterType: string, name: string, value: boolean) => {
    setIsFiltered(true);
    switch (filterType) {
      case 'unSigned':
        unsigned(value);
        break;
      case 'active':
        active(value);
        break;
      default:
        updateFilter(filterType, name, value);
    }
  };

  //if name==label or label == name
  const unsigned = (value: boolean) => {
    if (value) {
      setFilters(
        filters?.map(function(filter) {
          if (filter.value) {
            if (filter.filterType === 'artist' && !unsignedArtists.includes(filter.name)) {
              return { ...filter, value: false };
            }
          }
          if ('unSigned' === filter.filterType) {
            return { ...filter, value: true };
          }
          return filter;
        })
      );
      //turning off active
    } else {
      setFilters(
        filters?.map(function(filter) {
          if (
            filter.filterType === 'artist' &&
            !(!activeArtists.includes(filter.name) && isFilterActiveType(filters, 'active'))
          ) {
            return { ...filter, value: true };
          } else if ('unSigned' === filter.filterType) {
            return { ...filter, value: false };
          }
          return filter;
        })
      );
    }
  };

  const active = (value: boolean) => {
    if (value) {
      setFilters(
        filters?.map(function(filter) {
          if (filter.value) {
            if (filter.filterType === 'artist' && !activeArtists.includes(filter.name)) {
              return { ...filter, value: false };
            }
          }
          if ('active' === filter.filterType) {
            return { ...filter, value: true };
          }
          return filter;
        })
      );
      //turning off active
    } else {
      setFilters(
        filters?.map(function(filter) {
          if (
            filter.filterType === 'artist' &&
            !(!unsignedArtists.includes(filter.name) && isFilterActiveType(filters, 'unSigned'))
          ) {
            return { ...filter, value: true };
          } else if ('active' === filter.filterType) {
            return { ...filter, value: false };
          }
          return filter;
        })
      );
    }
  };

  const resetFilters = () => {
    setFilters(
      filters?.map(filter =>
        'unSigned' == filter.filterType || 'active' == filter.filterType
          ? { ...filter, value: false }
          : { ...filter, value: true }
      )
    );
    setFollowersScale({
      min: 0,
      max: 100000
    });
  };

  const updateFilter = (filterType: string, name: string, value: boolean) => {
    const newFilters = filters?.map(filter =>
      filterType == filter.filterType && name == filter.name ? { ...filter, value: value } : filter
    );
    setFilters(newFilters);
  };

  const selectAll = (currentFilters: IFilter[], value: boolean) => {
    setFilters(
      filters?.map(filter =>
        objectInFilter(filter, currentFilters) ? { ...filter, value: value } : filter
      )
    );
  };

  const checkFilter = (filterType: string, name: string) => {
    const filter = filters.find(elem => elem.filterType == filterType && elem.name == name);
    if (filter) {
      return filter.value;
    } else {
      return false;
    }
  };

  const checkFilterConditions = (node: IGraphNode) => {
    let a = 0;
    let b = 0;
    a += filterGenre(node.genres) ? 1 : 0;
    b += 1;
    a += checkFilter('label', node.label) ? 1 : 0;
    b += 1;
    a += checkFilter('artist', node.id) ? 1 : 0;
    b += 1;
    a += checkFilter('community', node.description) ? 1 : 0;
    b += 1;
    a += checkFilter('cluster', getClusterName(node)) ? 1 : 0;
    b += 1;
    a += filterFollowers(parseInt(node.followers)) ? 1 : 0;
    b += 1;
    return a === b;
  };

  const filterFollowers = (followers: number) => {
    if (followersScale.min <= followers && followersScale.max >= followers) {
      return true;
    } else if (followersScale.min <= followers && followersScale.max === 100000) {
      return true;
    }
    return false;
  };

  const filterGenre = (genres: string[]) => {
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].filterType == 'genre') {
        if (genres.includes(filters[i].name) && filters[i].value) return true;
      }
    }
    return false;
  };

  return {
    filters,
    setFilters,
    onChangeFilter,
    selectAll,
    checkFilterConditions,
    followersScale,
    onChangeFilterFollowerScale,
    resetFilters,
    setActiveArtists,
    setUnsignedArtists,
    isFiltered,
    setIsFiltered
  };
};
