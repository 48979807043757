import React from 'react';

interface SummaryPointProps {
  amount: string;
  description: string;
}

const SummaryPoint: React.FC<SummaryPointProps> = ({ amount, description }) => {
  return (
    <p className="mt-1 text-lg text-white">
      {amount} <span className=" ml-2 text-xs font-light">{description}</span>
    </p>
  );
};
export default SummaryPoint;
