import React, { useState } from 'react';
import { IGraphData } from '../graph/GraphHooks';
import GraphTitle from './GraphTitle';
import { InputSearch } from './InputSearch';
import list from '../../assets/list.png';
import listLight from '../../assets/list-light.png';
import node from '../../assets/node.png';
import nodeLight from '../../assets/node-light.png';

interface ArtistGraphHeaderProps {
  subject: string;
  graphType: string;
  search: any;
  data: IGraphData;
  listView: boolean;
  setSearch: React.Dispatch<React.SetStateAction<any>>;
  setListView: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ArtistGraphHeader: React.FC<ArtistGraphHeaderProps> = ({
  graphType,
  subject,
  search,
  listView,
  setListView,
  setSearch
}) => {
  return (
    <div className="flex flex-col">
      <div className="md:flex justify-between">
        <div className="">
          <GraphTitle mappingType={graphType} subject={subject} />
        </div>
        <InputSearch search={search} graphType={graphType} setSearch={setSearch} />
      </div>
      <div className="p-2 rounded space-x-2 inline-block ml-auto mt-8 w-max-fit bg-white shadow">
        <button onClick={() => setListView(false)}>
          <img className="w-5" src={listView ? nodeLight : node} alt="Map view" />
        </button>
        <button onClick={() => setListView(true)}>
          {' '}
          <img className="w-5" src={listView ? list : listLight} alt="List view" />
        </button>
      </div>
    </div>
  );
};
