import React, { useEffect, useState } from 'react';
import { searchArtists } from '../services/spotifyService';

interface SearchSuggestionProps {
  input: string;
  isComponentVisible: boolean;
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setArtist: React.Dispatch<any>;
}

const SearchSuggestion: React.FC<SearchSuggestionProps> = ({
  input,
  isComponentVisible,
  setArtist,
  setIsComponentVisible
}) => {
  const [artistSuggestions, setArtistSuggestions] = useState([]);
  const [seeMore, setSeeMore] = useState(false);

  useEffect(() => {
    if (!isComponentVisible) {
      setIsComponentVisible(true);
    }
  }, [input]);

  useEffect(() => {
    const fetch = async () => {
      const response = await searchArtists(input);
      if (response) {
        setArtistSuggestions(response.data.artists.items);
      } else {
        setArtistSuggestions(null);
      }
    };
    fetch();
  }, [input]);

  const onClickHandler = (artist: any) => {
    setArtist(artist);
  };
  return artistSuggestions !== null && isComponentVisible ? (
    <div className="absolute overflow-x-scroll max-h-96 top-0 right-0 left-0 z-50 text-center shadow">
      <div className="bg-white  ">
        <ul className="relative shadow-md rounded">
          {artistSuggestions.slice(0, seeMore ? 10000 : 5).map((artist: any) => {
            return (
              <button
                type="button"
                key={artist['external_urls']['spotify']}
                className="flex justify-start w-full py-2 hover:bg-gray-200"
                onClick={e => onClickHandler(artist)}
              >
                <div
                  className="bg-cover rounded-full h-7 w-7 mx-2 flex my-auto"
                  style={{
                    backgroundImage: `url(${artist.images.length ? artist.images[0].url : null})`
                  }}
                ></div>
                <p className="text-xs my-auto">{artist.name}</p>
              </button>
            );
          })}
          <button
            type="button"
            key={'seeMore'}
            className="w-full py-2 px-2"
            onClick={e => setSeeMore(!seeMore)}
          >
            {seeMore ? (
              <p className="text-xs my-auto font-light underline">show less</p>
            ) : (
              <p className="text-center text-xs my-auto font-light underline">
                show all {artistSuggestions.length}
              </p>
            )}
          </button>
        </ul>
      </div>
    </div>
  ) : (
    <></>
  );
};
export default SearchSuggestion;
