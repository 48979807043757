import React from 'react';

interface SummaryTitleProps {
  title: string;
}

const SummaryTitle: React.FC<SummaryTitleProps> = ({ title }) => {
  return <h1 className="text-white  mb-7">{title}</h1>;
};

export default SummaryTitle;
