import React, { useState } from 'react'
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css"


function FilterFollowerRange(props) {

    return (
        <div>
            <div className=" overflow-x-hidden w-full p-6 ">
                <h3 className="text-xs text-gray-500 mb-8">Followers</h3>
                <InputRange
                    step={10000}
                    maxValue={100000}
                    minValue={0}
                    formatLabel={value => value === 100000 ? `${value}+` : value}
                    value={props.followersScale}
                    onChange={props.onChangeFilterFollowerScale} />
            </div>
            <div className="mx-4 mt-2  border-b border-gray-200">
            </div>
        </div>
    );
}
export default FilterFollowerRange