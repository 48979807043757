import React, { useEffect, useState } from 'react';
import close from '../../../assets/close.png';
import { objectInObjectArray } from '../../../helpers/functions/arrayOfObjects';
import { useComponentVisible } from '../../../helpers/hooks/ComponentVisibleHooks';
import SearchSuggestion from '../../../components/SearchSuggestion';

interface ConnectionAddTargetsProps {
  toggle: boolean;
  artistSelection: any[];
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConnectionAddTargets: React.FC<ConnectionAddTargetsProps> = ({
  artistSelection,
  toggle,
  setIsComponentVisible
}) => {
  return (
    <div>
      <button
        onClick={e => setIsComponentVisible(true)}
        type="button"
        className="relative mr-2 p-1 px-3  rounded-full hover:border-black hover:border bg-white hover:bg-gray-200 font-extralight text-xs lg:text-base text-black"
      >
        <p className="absolute -right-0 -top-2 bg-primary rounded-full h-4 w-4 flex items-center justify-center text-white text-xs  ">
          {artistSelection.length}
        </p>
        {toggle ? 'Add outer' : 'Add inner'}
      </button>
    </div>
  );
};

interface ConnectionPopUpProps {
  toggle: boolean;
  artistSelection: any[];
  setArtistSelection: React.Dispatch<React.SetStateAction<any[]>>;
  isComponentVisible: boolean;
  degrees: number;
  setDegrees: React.Dispatch<React.SetStateAction<number>>;
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConnectionPopUp: React.FC<ConnectionPopUpProps> = ({
  toggle,
  artistSelection,
  setDegrees,
  degrees,
  setArtistSelection,
  isComponentVisible: isPopUpVisible
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [input, setInput] = useState('');
  const [artist, setArtist] = useState();

  //add artist to selections
  useEffect(() => {
    if (artist) {
      setInput('');
      addArtist(artist);
    }
  }, [artist]);

  //delete artist from selections
  const removeArtist = (artist: any) => {
    const newSelection = artistSelection.filter(item => item['id'] !== artist['id']);
    setArtistSelection(newSelection);
  };

  //add artist to selections
  const addArtist = (artist: any) => {
    if (!objectInObjectArray('id', artist['id'], artistSelection)) {
      setArtistSelection(prev => [...prev, artist]);
    }
  };

  const clearArtistSelection = () => {
    setArtistSelection([]);
  };

  //TODO: clean up clean targets
  return isPopUpVisible ? (
    <div className="absolute -left-2 lg:-right-80 top-1 z-50 px-2 pt-2 rounded shadow-md bg-gray-100">
      <div className="flex justify-between">
        <div ref={ref} className=" w-full ">
          <abbr title={''}>
            <input
              placeholder={toggle ? 'add an outer artist' : 'add an inner artist'}
              className=" w-full mr-2 p-1 pl-4   border-gray-400 rounded-full placeholder-gray-400 bg-white"
              value={input}
              onChange={e => setInput(e.target.value)}
            />
            <div className="relative">
              <SearchSuggestion
                input={input}
                setIsComponentVisible={setIsComponentVisible}
                isComponentVisible={isComponentVisible}
                setArtist={setArtist}
              />
            </div>
          </abbr>
        </div>
      </div>
      <div className="flex m-2 w-full mt-4  ">
        <p className="font-thin">What is the max number of connections? </p>
        <select
          onChange={e => setDegrees(Number(e.target.value))}
          className="block w-10  bg-white  border-gray-400 hover:border-gray-500 my-auto ml-2 px-1 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          value={degrees}
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
        </select>
        <button
          onClick={clearArtistSelection}
          className="ml-auto mr-2 underline font-light text-gray-600"
        >
          clear
        </button>
      </div>

      <ul className="flex flex-wrap max-h-32  overflow-y-scroll">
        {artistSelection.map(artist => (
          <ConnectionArtistSelect key={artist['id']} artist={artist} removeArtist={removeArtist} />
        ))}
      </ul>
    </div>
  ) : (
    <></>
  );
};

interface ConnectionArtistSelectProps {
  artist: any;
  removeArtist: (artist: any) => void;
}

const ConnectionArtistSelect: React.FC<ConnectionArtistSelectProps> = ({
  artist,
  removeArtist
}) => {
  return (
    <div className="flex relative justify-between my-1 mr-2 bg-white shadow p-2  rounded">
      <button onClick={() => removeArtist(artist)} className="absolute -right-0 -top-0">
        <img className=" h-2 " src={close} alt="close" />
      </button>
      <div
        className="bg-cover rounded-full h-5 w-5 mr-2 flex my-auto"
        style={{
          backgroundImage: `url(${artist.images.length ? artist.images[0].url : null})`
        }}
      ></div>
      <p className="hidden lg:block my-auto font-light text-xs">{artist['name']}</p>
    </div>
  );
};
