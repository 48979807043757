import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from './AuthProvider';
import { toastError, toastSuccess } from '../helpers/functions/toasts';

export const saveTokenInLocalStorage = (tokenDetails: any) => {
  localStorage.setItem('userDetails', tokenDetails);
};

export const useAuth = () => {
  const { setAuthenticated, setUser } = useContext(AuthContext);
  const history = useHistory();

  async function signIn(username: string, password: string) {
    try {
      const user = await Auth.signIn(username, password);
      if (typeof setAuthenticated === 'function') {
        setUser(user);
        setAuthenticated(true);
      }
      history.push('/app/community');
    } catch (error) {
      const authError = error as any;
      toastError(authError.message);
      console.log('error signing in', error);
      if (typeof setAuthenticated === 'function') {
        setAuthenticated(false);
      }
    }
  }

  async function signOut() {
    try {
      await Auth.signOut();
      if (typeof setAuthenticated === 'function') {
        setAuthenticated(false);
        history.push('/auth/login');
      }
    } catch (error) {
      const authError = error as any;
      toastError(authError.message);
      console.log('error signing out: ', error);
    }
  }

  async function signUp(username: string, email: string, password: string) {
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email //email
          // other custom attributes
        }
      });
      toastSuccess('Verification code sent to your email!');

      return user;
    } catch (error) {
      const authError = error as any;
      toastError(authError.message);
      console.log('error signing up:', error);
    }
  }

  async function confirmSignUp(username: string, code: string) {
    try {
      await Auth.confirmSignUp(username, code);
      history.push('/auth/login');
      toastSuccess('Verified!');
    } catch (error) {
      const authError = error as any;
      toastError(authError.message);
      console.log('error confirming sign up', error);
    }
  }

  async function resendConfirmationCode(username: string) {
    try {
      await Auth.resendSignUp(username);
      console.log('code resent successfully');
    } catch (err) {
      console.log('error resending code: ', err);
    }
  }

  return {
    signIn,
    signOut,
    signUp,
    confirmSignUp
  };
};
