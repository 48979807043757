import React from 'react';
import { ILegendPoint } from './GraphHooks';

interface GraphLegendProps {
  legend: ILegendPoint[];
}

const GraphLegend: React.FC<GraphLegendProps> = ({ legend }) => {
  return (
    <div className="absolute right-4 bottom-4 m-1 bg-transparent">
      {legend.map(point => (
        <GraphLegendPoint key={point['color']} point={point} />
      ))}
    </div>
  );
};

interface GraphLegendPointProps {
  point: ILegendPoint;
}

const GraphLegendPoint: React.FC<GraphLegendPointProps> = ({ point }) => {
  return (
    <div className="flex">
      <div className="w-2 h-2 mr-2 my-auto ">
        <p style={{ backgroundColor: point.color }} className="rounded-full h-2 w-2 min-w-full"></p>
      </div>
      <h3 className="font-thin mr-1 text-xs  ">{point.description}</h3>
    </div>
  );
};

export default GraphLegend;
