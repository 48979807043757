import React, { ReactElement } from 'react';

interface ModalProps {
  show: boolean;
  children: ReactElement<any, any>;
  refObj: React.MutableRefObject<any>;
}
const Modal: React.FC<ModalProps> = ({ refObj, show, children }) => {
  return show ? (
    <div className="fixed z-50 top-0 left-0 bottom-0 right-0 bg-smokeLight">
      <section ref={refObj} className="">
        {children}
      </section>
    </div>
  ) : (
    <></>
  );
};
export default Modal;
