import { Analytics, Auth } from 'aws-amplify';
import React, { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ArtistGraphContainer } from '../../../components/graph/ArtistGraphContainer';
import { APP_NAME } from '../../../constants/global';

export const CommunityContainer: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [source, setSource] = useState(
    searchParams.get('artist')
      ? searchParams.get('artist')
      : 'https://open.spotify.com/artist/6AyATGg7mDgBlZ4N5uNog0?si=qjG8FnqwTsG4xXFx6Qx6GQ&dl_branch=1'
  );
  useEffect(() => {
    document.title = `${APP_NAME} - Community`;
  }, []);

  //  useEffect(()=>{
  //     const fetchData = async (): Promise<void> => {
  //         const user = await Auth.currentAuthenticatedUser()
  //         Analytics.record({
  //             name:"pageVisit",
  //             attributes:{
  //               userEmail:user.attributes.email
  //             }

  //           })
  //     }
  //     fetchData()
  //  },[])

  return (
    <ArtistGraphContainer
      path={'/community'}
      graphType={'Community'}
      params={{
        degreesOfFreedom: 2,
        start: source,
        startName: '',
        versionDate: '',
        reversed: true
      }}
    />
  );
};
