import React, { useState } from 'react';
import { Checkbox } from './Checkbox';
import downArrow from '../../assets/down-arrow.png';
import upArrow from '../../assets/up-arrow.png';
import resetArrow from '../../assets/arrow-circle.png';
import { IFilter } from './FilterHooks';
import FilterFollowerRange from './FilterFollowerRange';

interface FilterContainerProps {
  filters: IFilter[] | undefined;
  onChangeFilter: (filterType: string, name: string, value: boolean) => void;
  resetFilters: () => void;
  selectAll: (currentFilters: IFilter[], value: boolean) => void;
  followersScale: {
    min: number;
    max: number;
  };
  onChangeFilterFollowerScale: (value: { min: number; max: number }) => void;
}

const FilterContainer: React.FC<FilterContainerProps> = ({
  filters,
  onChangeFilter,
  onChangeFilterFollowerScale,
  resetFilters,
  selectAll,
  followersScale
}) => {
  return (
    <div className="max-h-screen ">
      <div className="flex justify-between mx-4 my-4">
        <h2 className="font-bold text-lg">Filters</h2>
        <button className="flex text-xs text-gray-700" onClick={e => resetFilters()}>
          <p className="m-auto">Reset</p>
          <img className="h-3 w-3 ml-2 my-auto" src={resetArrow} />
        </button>
      </div>
      <div className="mx-4 mt-4 pb-1 border-b border-gray-200">
        {filters?.map(function(filter) {
          if (filter.filterType === 'unSigned') {
            return (
              <div key={'unsignedKey'} className="mb-2">
                <Checkbox
                  key={filter.filterType + filter.name}
                  filterType={filter.filterType}
                  onChangeFilter={onChangeFilter}
                  checkbox={{ name: filter.name, isChecked: filter.value }}
                />
              </div>
            );
          } else if (filter.filterType === 'active') {
            return (
              <div key={'activeKey'} className="mb-2">
                <Checkbox
                  key={filter.filterType + filter.name}
                  filterType={filter.filterType}
                  onChangeFilter={onChangeFilter}
                  checkbox={{ name: filter.name, isChecked: filter.value }}
                />
              </div>
            );
          }
        })}
      </div>
      <FilterFollowerRange
        key={'followerRange'}
        followersScale={followersScale}
        onChangeFilterFollowerScale={onChangeFilterFollowerScale}
      />

      {/* <div className="flex">
                <div className="py-1 px-4 border m-1 border-primary rounded-full">
                <p className=" text-gray-500 text-2xs font-bold">{"genre"}</p>
                </div>
                <div className="py-1 px-4 border m-1 border-primary rounded-full">
                    <p className=" text-gray-500 text-2xs font-bold">{"genre"}</p>
                </div>
                </div> */}
      <DisplayCheckboxes
        key={'genreFilter'}
        filters={filters}
        onChangeFilter={onChangeFilter}
        selectAll={selectAll}
        filterType={'genre'}
      />
      <DisplayCheckboxes
        key={'labelFilter'}
        filters={filters}
        onChangeFilter={onChangeFilter}
        selectAll={selectAll}
        filterType={'label'}
      />
      <DisplayCheckboxes
        key={'artistFilter'}
        filters={filters}
        onChangeFilter={onChangeFilter}
        selectAll={selectAll}
        filterType={'artist'}
      />
      <DisplayCheckboxes
        key={'clusterFilter'}
        filters={filters}
        onChangeFilter={onChangeFilter}
        selectAll={selectAll}
        filterType={'cluster'}
      />
      <DisplayCheckboxes
        key={'communityFilter'}
        filters={filters}
        onChangeFilter={onChangeFilter}
        selectAll={selectAll}
        filterType={'community'}
      />
    

    </div>
  );
};

interface DisplayCheckboxesProps {
  filters: IFilter[] | undefined;
  onChangeFilter: (filterType: string, name: string, value: boolean) => void;
  selectAll: (currentFilters: IFilter[], value: boolean) => void;
  filterType: string;
}

const DisplayCheckboxes: React.FC<DisplayCheckboxesProps> = ({
  filters,
  filterType,
  onChangeFilter,
  selectAll
}) => {
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [search, setSearch] = useState('');

  const dynamicSearch = () => {
    if (filters) {
      return filters.filter(
        item =>
          item.filterType === filterType && item.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return [];
  };
  return (
    <div className="mx-4 pb-1  border-b border-gray-200">
      <button className="flex w-full h-full py-4" onClick={e => setShowCheckboxes(!showCheckboxes)}>
        <div className="flex my-auto">
          <img className="h-3 w-3" src={showCheckboxes ? upArrow : downArrow} />
        </div>
        <h3 className="ml-4 text-xs text-gray-500">
          {filterType.charAt(0).toUpperCase() + filterType.slice(1)}
        </h3>
      </button>
      {showCheckboxes ? (
        <div>
          <div className="flex justify-between text-xs mb-2 underline text-primary">
            <button onClick={e => (dynamicSearch() ? selectAll(dynamicSearch(), true) : null)}>
              select all
            </button>
            <button onClick={e => (dynamicSearch() ? selectAll(dynamicSearch(), false) : null)}>
              clear
            </button>
          </div>
          <input
            className="w-full mt-2 mb-1  p-2 border border-black text-xs"
            placeholder={'Search ' + filterType}
            type="text"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />

          <div className=" max-h-60 overflow-scroll ">
            {dynamicSearch()?.map(filter =>
              filter.filterType === filterType ? (
                <Checkbox
                  key={filter.name + filter.filterType}
                  filterType={filter.filterType}
                  onChangeFilter={onChangeFilter}
                  checkbox={{ name: filter.name, isChecked: filter.value }}
                />
              ) : (
                <></>
              )
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FilterContainer;
