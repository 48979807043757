import { IFilter } from "../../components/settings/FilterHooks";

export const objectInFilter = (obj:IFilter, filters:IFilter[]) => {
  
    for (let i = 0; i < filters.length; i++) {
        if (filters[i].filterType === obj.filterType && filters[i].name === obj.name) {
            return true;
        }
    }
    return false;
}

export const sortFilters = (items: IFilter[]) =>{
    return items.sort(function(a, b) {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        else if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      });
}

export const isFilterActive = (filters: IFilter[], filterType: string, name:string)=>{
    
    for (let i = 0; i < filters.length; i++) {
      if(filters[i].filterType === filterType && filters[i].name === name){
        return filters[i].value
      }
    }
    return false
  }

export const isFilterActiveType = (filters: IFilter[], filterType: string)=>{
  for (let i = 0; i < filters.length; i++) {
    if(filters[i].filterType === filterType){
      return filters[i].value
    }
  }
  return false
}