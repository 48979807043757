import axios from 'axios';
import axiosRetry from 'axios-retry';


const getSpotifyAccessToken = async (): Promise<any> => {
    try {
        const res = await axios.get("https://7ies9dmuha.execute-api.us-west-2.amazonaws.com/staging");
        const contents = JSON.parse(res.data.body)
        return {
            headers: {
                Authorization: `Bearer ${contents.accessToken}`
            }
        }
    } catch (error) {
        return {
            headers: {
                Authorization: `Bearer ${"accessToken"}`
            }
        }
  }
}

const getSearchArtistsEndpoint = (query:string) => {
    return `https://api.spotify.com/v1/search?q=${query}&type=artist&limit=${50}`;
}

const getArtistAlbumsEndpoint = (artistId:string, offset=0, market="US") => {
    return `https://api.spotify.com/v1/artists/${artistId}/albums?limit=${50}&offset=${offset}&market=${market}&include_groups=album,single,compilation`;
}

const getAlbumTracksEndpoint = (albumId:string, offset=0, market="US") => {
    return `https://api.spotify.com/v1/albums/${albumId}/tracks?limit=${50}&offset=${offset}&market=${market}`;
}

const getSeveralTracksEndpoint = (trackIds:string) => {
    return `https://api.spotify.com/v1/tracks?ids=${trackIds}`;
}

const getSeveralAlbumsEndpoint = (albumIds:string) => {
    return `https://api.spotify.com/v1/albums?ids=${albumIds}`;
}

export const getSeveralAlbums = async (albumIds: string) => {
    const header = await getSpotifyAccessToken();

    // Configure axios-retry on a specific request
    const axiosInstance = axios.create();
    axiosRetry(axiosInstance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

    try {
        return await axiosInstance.get(getSeveralAlbumsEndpoint(albumIds), header);
    } catch (error) {
        return "error";
    }
}

export const getSeveralTracks = async (trackIds: string) => {
    const header = await getSpotifyAccessToken();

    // Configure axios-retry on a specific request
    const axiosInstance = axios.create();
    axiosRetry(axiosInstance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

    try {
        return await axiosInstance.get(getSeveralTracksEndpoint(trackIds), header);
    } catch (error) {
        return "error";
    }
};


export const getArtistAlbums = async (artistId:string, offset=0) => {
    const header = await getSpotifyAccessToken()
    const axiosInstance = axios.create();
    axiosRetry(axiosInstance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });
    try {
        return await axiosInstance.get(getArtistAlbumsEndpoint(artistId, offset),header );
    } catch (error) {
        return "error"
    }
}

export const getAlbumTracks = async (albumId:string, offset=0) => {
    const header = await getSpotifyAccessToken()
    const axiosInstance = axios.create();
    axiosRetry(axiosInstance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });
    try {
        return await axiosInstance.get(getAlbumTracksEndpoint(albumId, offset),header );
    } catch (error) {
        return "error"
    }
} 

export const searchArtists = async (query:string) => {
    const axiosInstance = axios.create();
    axiosRetry(axiosInstance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });
    if (query){
        const header = await getSpotifyAccessToken()
        try {
            return await axiosInstance.get(getSearchArtistsEndpoint(query),header );
        } catch (error) {
            return null
    }
}
    return null
}

