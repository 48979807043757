import React from 'react';
import { useHistory } from 'react-router-dom';
import { APP_NAME } from '../../constants/global';

interface Props {
  isLogin: boolean;
}

const HomeFooter: React.FC<Props> = ({ isLogin }) => {
  const history = useHistory();
  //Go to signup
  const handleClick = () => {
    isLogin ? history.push('/auth/signup') : history.push('/auth/login');
  };
  return (
    <footer className="flex justify-between w-full ">
      <p className="p-4 absolute bottom-0 left-0">{APP_NAME} | Privacy</p>
      <p className="p-4 absolute bottom-0 right-0">
        {isLogin ? 'Create an account ' : 'Login in '}
        <button onClick={handleClick} className="underline">
          here
        </button>
      </p>
    </footer>
  );
};

export default HomeFooter;
