import { useEffect, useState } from 'react';
import searchbarIcon from '../../../assets/search-dark.png';
import DatePicker from '../../../components/DatePicker';
import { useFetchGenres } from '../../../dataHandlers/genresHandler';
import { useComponentVisible } from '../../../helpers/hooks/ComponentVisibleHooks';

interface GenreSearchProps {
  search: any;
  setSearch: React.Dispatch<React.SetStateAction<any>>;
}

const GenreSearch: React.FC<GenreSearchProps> = ({ search, setSearch }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [input, setInput] = useState('');
  const [genre, setGenre] = useState('');
  const [newGenre, setNewGenre] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(search.versionDate);

  useEffect(() => {
    if (genre) {
      setInput(genre);
      setNewGenre(!newGenre);
    }
  }, [genre]);

  useEffect(() => {
    setIsComponentVisible(false);
  }, [newGenre]);

  const onSubmitHandler = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    if (input) {
      setSearch({ genre: input, versionDate: currentVersion });
    }
  };

  return (
    <form onSubmit={onSubmitHandler} className="flex rounded-full my-auto  h-8">
      <div ref={ref} className=" mr-2">
        <abbr title={'genre'}>
          <input
            placeholder={'genre'}
            className=" w-full mr-2 p-1 pl-4 rounded-full placeholder-gray-400 bg-white"
            value={input}
            onChange={e => setInput(e.target.value)}
          />
        </abbr>
        <div className="relative">
          {' '}
          <SearchSuggestion
            input={input}
            setIsComponentVisible={setIsComponentVisible}
            isComponentVisible={isComponentVisible}
            setGenre={setGenre}
          />
        </div>
      </div>
      <DatePicker currentVersion={currentVersion} setCurrentVersion={setCurrentVersion} />
      <button className=" ml-6 mx-auto px-4 p-0 rounded text-center bg-white hover:bg-gray-200 ">
        <img className="h-3 w-3" src={searchbarIcon} />
      </button>
    </form>
  );
};

interface SearchSuggestionProps {
  input: string;
  isComponentVisible: boolean;
  setIsComponentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setGenre: React.Dispatch<any>;
}

const SearchSuggestion: React.FC<SearchSuggestionProps> = ({
  input,
  isComponentVisible,
  setGenre,
  setIsComponentVisible
}) => {
  const { data: genres } = useFetchGenres();

  const [seeMore, setSeeMore] = useState(false);

  const dynamicSearch = () => {
    if (genres && input != '') {
      const filteredGenres = genres.filter(value => value.includes(input.toLocaleLowerCase()));
      return filteredGenres;
    }
    return [];
  };

  useEffect(() => {
    setSeeMore(false);
    if (!isComponentVisible && input) {
      setIsComponentVisible(true);
    }
  }, [input]);

  const onClickHandler = (genre: string) => {
    setGenre(genre);
  };
  return input !== '' && isComponentVisible ? (
    <div className="absolute max-h-96 overflow-x-scroll right-0 left-0 z-50 shadow-md  text-center">
      <div className="bg-white  ">
        <ul className="relative rounded">
          {dynamicSearch()
            ?.slice(0, seeMore ? 10000 : 5)
            .map((genre: string, idx: number) => {
              return (
                <button
                  type="button"
                  key={idx}
                  className="flex justify-start w-full py-2 px-2 hover:bg-gray-200"
                  onClick={e => onClickHandler(genre)}
                >
                  <p className="text-xs my-auto">{genre}</p>
                </button>
              );
            })}
          <button
            type="button"
            key={'seeMore'}
            className="w-full py-2 px-2"
            onClick={e => setSeeMore(!seeMore)}
          >
            {seeMore ? (
              <p className="text-xs my-auto font-light underline">show less</p>
            ) : (
              <p className="text-center text-xs my-auto font-light underline">
                show all {dynamicSearch().length}
              </p>
            )}
          </button>
        </ul>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default GenreSearch;
