import { useEffect, useState } from 'react';
import SearchSuggestion from '../../../components/SearchSuggestion';
import { useComponentVisible } from '../../../helpers/hooks/ComponentVisibleHooks';

interface ScheduleASearchArtistProps {
  placeholder: string;
  artist: any;
  setArtist: React.Dispatch<any>;
}
const ScheduleASearchArtist: React.FC<ScheduleASearchArtistProps> = ({
  placeholder,
  artist,
  setArtist
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [input, setInput] = useState('');
  const [newArtist, setNewArtist] = useState(false); //handle getting rid of suggestions when new artist is set

  useEffect(() => {
    if (artist) {
      setInput(artist['name']);
      setNewArtist(!newArtist);
    }
  }, [artist]);

  useEffect(() => {
    setIsComponentVisible(false);
  }, [newArtist]);

  return (
    <div ref={ref} className="mr-2">
      <abbr title={placeholder}>
        <input
          placeholder={placeholder}
          className=" w-full mr-2 p-2 pl-4 rounded-md border border-black  placeholder-gray-400 "
          value={input}
          onChange={e => setInput(e.target.value)}
        />
      </abbr>
      <div className="relative">
        {' '}
        <SearchSuggestion
          input={input}
          setIsComponentVisible={setIsComponentVisible}
          isComponentVisible={isComponentVisible}
          setArtist={setArtist}
        />
      </div>
    </div>
  );
};

export default ScheduleASearchArtist;
