import React, { useEffect, useState, useRef } from 'react';
import G6 from '@antv/g6';
import { IGraphData, IGraphNode, ILegendPoint } from './GraphHooks';
import { transformAntVData } from '../../helpers/functions/graphDataTransform';
import ExpandGraph from './ExpandGraph';
import GraphLegend from './GraphLegend';
import Loading from '../Loading';

interface Props {
  filteredData: Map<string, IGraphNode>;
  data: IGraphData;
  legend: ILegendPoint[];
  handleClick: (nodeId: string) => void;
  expandGraph: boolean;
  setExpandGraph: React.Dispatch<React.SetStateAction<boolean>>;
  gpuEnabled?: boolean;
}

export const Graph: React.FC<Props> = ({
  data,
  filteredData,
  expandGraph,
  legend,
  gpuEnabled = false,
  setExpandGraph,
  handleClick
}) => {
  const ref = useRef<HTMLDivElement>(null);

  // let graph: any = null;
  const [graph, setGraph] = useState(null);

  // tool tip
  const [ticking, setTicking] = useState(true);

  const clearNodes = () => {
    graph.getEdges()?.forEach(function(edge: any) {
      graph.clearItemStates(edge);
    });
    graph.getNodes()?.forEach(function(node: any) {
      if (node) {
        graph.setItemState(node, 'active', false);
        graph.setItemState(node, 'inactive', false);
      }
    });
  };

  const filterEvents = (filteredData: any) => {
    clearNodes();
    graph.getNodes()?.forEach(function(node: any) {
      if (filteredData.has(node._cfg.id)) {
        graph.setItemState(node, 'filtered', true);
      } else {
        graph.setItemState(node, 'filtered', false);
      }
    });
  };

  const bindEvents = () => {
    if (!graph || !graph.destroyed) {
      if (ref || ref.current.offsetWidth || ref.current.offsetHeight) {
        const resizeObserver = new ResizeObserver(entries => {
          if (ref.current && !graph.destroyed) {
            graph.changeSize(ref.current.offsetWidth, ref.current.offsetHeight);
          }
        });
        resizeObserver.observe(ref.current);
      }
    }

    const highlightNeighbourNodes = (item: any) => {
      clearNodes();
      graph.getNodes().forEach(function(node: any) {
        graph.setItemState(node, 'inactive', true);
      });
      graph.setItemState(item, 'active', true); //activate current node
      graph.getNeighbors(item).forEach(function(node: any) {
        node._cfg.edges.forEach(function(edge: any) {
          if (edge._cfg.model.source === item._cfg.model.id) {
            graph.setItemState(edge, 'active', true);
            graph.setItemState(node, 'active', true);
          }
        });
      });
    };

    // Gets rid of all events on edges so that they don't interfere with the canvas drag
    graph.getEdges().forEach((edge: any) => {
      const group = edge.getContainer();
      group.set('capture', false);
    });

    graph.on('afterlayout', (e: any) => {
      setTicking(false);
    });

    graph.on('node:click', (evt: any) => {
      const { item } = evt;
      const model = item.getModel();
      const { id } = model;
      handleClick(id);
    });

    graph.on('node:mouseenter', (evt: any) => {
      const { item, target } = evt;
      if (target.cfg.type === 'text') {
        return;
      }
      highlightNeighbourNodes(item);
    });

    graph.on('node:touchstart', (evt: any) => {
      // clearNodes();
      const { item } = evt;
      graph.setItemState(item, 'selected', true); // set node style
      highlightNeighbourNodes(item);
    });

    graph.on('canvas:touchstart', (evt: any) => {
      clearNodes();
    });

    graph.on('node:mouseout', (evt: any) => {
      clearNodes();
    });
  };

  useEffect(() => {
    if (graph) {
      graph.destroy();
    }
    if (data) {
      const temp = new G6.Graph({
        container: ref.current,
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
        fitView: true,
        modes: {
          default: [
            'drag-node',
            {
              type: 'drag-canvas',
              enableOptimize: false
              // allowDragOnItem: true,
            },
            {
              type: 'zoom-canvas',
              sensitivity: 1,
              enableOptimize: false
            }
          ]
        },
        defaultNode: {
          shape: 'node',

          labelPosition: 'right',
          // 节点文本样式
          labelCfg: {
            position: 'right',
            style: {
              fill: '#000',
              fontFamily: 'sans-serif',
              fontSize: 10
            }
          },
          // 节点默认样式
          style: {
            stroke: '#72CC4A',
            width: 150
          }
        },
        defaultEdge: {
          shape: 'polyline',
          style: {
            endArrow: true
          }
        },
        // 节点交互状态配置
        nodeStateStyles: {
          inactive: {
            opacity: 0.2
          },
          active: {
            opacity: 1
          },
          filtered: {
            opacity: 0.2
          }
        },
        edgeStateStyles: {
          hover: {
            stroke: 'blue',
            lineWidth: 3
          }
        },
        layout: {
          type: 'gForce',
          preventOverlap: true,
          maxIteration: gpuEnabled ? 1000 : 100,
          edgeStrength: 500,
          gpuEnabled: gpuEnabled // Whether to enable the GPU parallel computing, supported by G6 4.0
        }
      });
      setGraph(temp);
    }
  }, [data]);

  useEffect(() => {
    if (graph && !graph.destroyed) {
      graph.data(transformAntVData(data));
      graph.render();
      bindEvents();
    }
  }, [graph]);

  useEffect(() => {
    if (graph) {
      filterEvents(filteredData);
    }
  }, [filteredData]);

  return (
    <div className='relative w-full h-full shadow rounded-l bg-white'>
      <div className={`absolute inset-0 ${ticking ? 'visible' : 'invisible'}`}>
        <Loading className="absolute left-4 top-4 w-8 h-8 border-2" />
      </div>
      <div className={`absolute inset-0 ${ticking ? 'invisible' : 'visible'}`} ref={ref}>
        <ExpandGraph expandGraph={expandGraph} setExpandGraph={setExpandGraph} />
        <GraphLegend legend={legend}/>
      </div>
    </div>

  ) 
}
