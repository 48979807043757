import { useState, useEffect } from 'react';
import { IDatePage } from '../components/graph/GraphHooks';
import { API, Auth } from 'aws-amplify';
import { getPages } from '../helpers/functions/getDatePages';

export const useFetchVersions = () => {
  const [data, setData] = useState<IDatePage>();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    setError(false);

    const fetchData = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const requestInfo = {
          headers: {
            Authorization: token
          }
        };
        const res = await API.get('mappingApi', '/getDates', requestInfo);
        const pages = getPages(res);
        setData(pages);
      } catch (error) {
        console.log(error);
        setError(true);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return {
    data,
    error,
    isLoading
  };
};
