import { Packer } from 'docx';
import saveAs from 'file-saver';
import { useContext, useEffect, useState } from 'react';
import { useFetchScheduleAHandler } from '../../../dataHandlers/scheduleAHandler';
import { JSONToCSVConvertor } from '../../../helpers/functions/JSONToCSVConvertor';
import { getAllArtistAlbums, getArtistTracks, getUniqueTrackNames, groupTracksByAlbum } from '../../../helpers/functions/spotifyHelpers';
import { useComponentVisible } from '../../../helpers/hooks/ComponentVisibleHooks';
import { generateScheduleA } from './ScheduleADocx';
import { AuthContext } from '../../../services/AuthProvider';

export interface IScheduleAAlbumTracks {
  name: string;
  isChecked: boolean;
  trackId: string;
  trackNumber: string;
}
export interface IScheduleA {
  albumId: string;
  albumName: string;
  albumType: string;
  releaseDate: string;
  albumTracks: IScheduleAAlbumTracks[];
}

export interface IScheduleATrack {
  email: string;
  track: string;
  artistId: string;
  artistName: string;
  isrc: string;
  row: number;
  isChecked:boolean;
}

export const useScheduleA = () => {
  const [search, setSearch] = useState<any>(null);
  const [artist, setArtist] = useState<any>(null);
  const [scheduleA, setScheduleA] = useState<IScheduleA[]>();
  const [metaData, setMetaData] = useState<any[]>();
  const [copyrightData, setCopyRightData] = useState<any[]>();
  const [trackList, setTrackList] = useState<IScheduleATrack[] | null>(null);
  const [trackListIsLoading, setTrackListIsLoading] = useState<boolean>(false); 
  const [albumList, setAlbumList] = useState<any[]>();
  const [saveIsrcs, setSaveIsrcs] = useState<boolean>(false); 
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const {user } = useContext(AuthContext);
  
  const { data: incomingData, isLoading: dataIsLoading, error } = useFetchScheduleAHandler(
    '/scheduleA',
    search
  );
  
  useEffect(() => {
    if (artist) {
      setTrackListIsLoading(true);
      getTrackList();
    } else {
      setTrackList(null);
    }
  }, [artist]);

  useEffect(() => {
    if (!dataIsLoading && incomingData) {
      setScheduleA(incomingData.scheduleA);
      setMetaData(incomingData.metaData);
      setCopyRightData(incomingData.copyrightData);
    }
  }, [incomingData, dataIsLoading]);

  useEffect(() => {
    if (scheduleA) {
      if (checkScheduleA()) {
        generateFiles();
      } else {
        setIsComponentVisible(true);
      }
    }
  }, [scheduleA, metaData]);

  const generateFiles = () => {
    createScheduleA();
    JSONToCSVConvertor(metaData, `metaData_${artist.name}`, true);
    JSONToCSVConvertor(copyrightData, `copyrightData_${artist.name}`, true);
    setIsComponentVisible(false);
  };
  const atLeastOneTrackSelected = (arrayOfObjects: any[]) => {
    if (arrayOfObjects) {
      for (const obj of arrayOfObjects) {
        if (obj.isChecked) {
          return true;
        }
      }
    }
    return false;
  };

  const getTrackList = () => {
    if (!artist) {
      return;
    }

    getArtistTracks(artist.id).then((tracks: Record<string, any>[]) => {
      setAlbumList(groupTracksByAlbum(tracks));      
      setTrackListIsLoading(false);
      const tracksWithUniqueIsrc = tracks.filter(
        (track: Record<string, any>, index: number, self: Record<string, any>[]) =>
          index === self.findIndex((t: Record<string, any>) => t.external_ids.isrc === track.external_ids.isrc)
      );
      const trackList: IScheduleATrack[] = [];

      let rowNumber = 0;
      for (let i = 0; i < tracksWithUniqueIsrc.length; i++) {
        trackList.push({
          email: user.attributes.email,
          track: tracksWithUniqueIsrc[i].name,
          artistId: artist.id,
          artistName: artist.name,
          isrc: tracksWithUniqueIsrc[i].external_ids.isrc,
          row: rowNumber,
          isChecked: false
        });
        rowNumber++;
      }
      
      setTrackList(trackList);
    });
  };

  const createScheduleA = () => {
    const doc = generateScheduleA(scheduleA);
    Packer.toBlob(doc).then(blob => {
      saveAs(blob, `scheduleA_${artist.name}.docx`);
    });
  };

  const checkScheduleA = () => {
    for (const album of scheduleA) {
      for (const track of album.albumTracks) {
        if (!track.isChecked) {
          return false;
        }
      }
    }
    return true;
  };

  return {
    scheduleA,
    search,
    artist,
    metaData,
    dataIsLoading,
    trackList,
    ref,
    isComponentVisible,
    trackListIsLoading,
    saveIsrcs,
    albumList,
    setSaveIsrcs,
    generateFiles,
    setIsComponentVisible,
    setTrackList,
    atLeastOneTrackSelected,
    setArtist,
    setSearch
  };
};
