import { useState, useEffect } from 'react';
import { IGraphData, IGraphNode } from '../components/graph/GraphHooks';
import { API, Auth } from 'aws-amplify';
import { toastError } from '../helpers/functions/toasts';
import { sortArrayOfObjects } from '../helpers/functions/arrayOfObjects';

export const useFetchGraphData = (path: string, params: any) => {
  const [data, setData] = useState<IGraphData>();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    setError(false);

    const fetchData = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        
        const requestInfo = {
          queryStringParameters: params,
          headers: {
            Authorization: token
          }
        };
        const res = await API.get('mappingApi', path, requestInfo);

        if (res['nodes'].length !== 0) {
          const sortedNodes: IGraphNode[] = sortArrayOfObjects(res.nodes.slice(1), 'followers');
          sortedNodes.unshift(res.nodes[0]);

          setData({
            links: res.links,
            nodes: sortedNodes,
            legend: res.legend
          });
        } else {
          setError(true);
        }
      } catch (error) {
        console.log(error);
        setError(true);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [params]);

  useEffect(() => {
    const handleErrors = () => {
      if (error) {
        switch (path) {
          case '/community':
            toastError('Artist not found');
            break;
          case '/genre':
            toastError('Genre does not exist');
            break;
          case '/connection':
            toastError('No connection found');
            break;
          case '/nettwerk':
            break;
        }
      }
    };
    handleErrors();
  }, [error]);

  return {
    data,
    error,
    isLoading
  };
};
